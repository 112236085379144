import { CreateButton, ExportButton, List } from "@refinedev/antd";

import { AllStopsMap, StopListTable } from "../../components/stop";
import { Flex, Segmented } from "antd";
import { useState } from "react";
import { useExport, useTranslate } from "@refinedev/core";
import { EnvironmentOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { IRoute, IStop } from "../../interfaces";

type View = "table" | "map";

export const StopsList = () => {
  const [view, setView] = useState<View>(
    (localStorage.getItem("store-view") as View) || "table",
  );

  const handleViewChange = (value: View) => {
    setView(value);
    localStorage.setItem("store-view", value);
  };

  const t = useTranslate();

  const { isLoading, triggerExport } = useExport<IStop>({
    mapData: (item) => {
      return {
        id: item.id,
        address: item.address,
        name: item.name,
        geo_location: item.geo_location,
        notes: item.notes
      };
    },
  });

  return (
    <>
      <List
        breadcrumb={false}
        headerButtons={(props) => [
          <Segmented<View>
            key="view"
            size="large"
            value={view}
            style={{ marginRight: 24 }}
            options={[
              {
                label: "",
                value: "table",
                icon: <UnorderedListOutlined />,
              },
              {
                label: "",
                value: "map",
                icon: <EnvironmentOutlined />,
              },
            ]}
            onChange={handleViewChange}
          />,
          <CreateButton {...props.createButtonProps} key="create" size="large">
            {t("stops.addNewStop")}
          </CreateButton>,
          <ExportButton
          size="large"
          onClick={triggerExport}
          loading={isLoading}
        />,
        ]}
      >
        {view === "table" && <StopListTable />}
        {view === "map" && (
          <Flex
            style={{
              height: "calc(100dvh - 232px)",
              marginTop: "32px",
            }}
          >
            <AllStopsMap />
          </Flex>
        )}
      </List>
    </>
  );
};
