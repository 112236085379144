import { useTranslate, useGetToPath, useGo } from "@refinedev/core";
import { SaveButton, useStepsForm, UseFormReturnType } from "@refinedev/antd";
import {
  Form,
  Input,
  Button,
  Steps,
  Modal,
  Flex,
  theme,
  InputNumber,
  Select,
} from "antd";
import { IVehicle } from "../../interfaces";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const VehicleCreate = () => {
  const t = useTranslate();
  const getToPath = useGetToPath();
  const [searchParams] = useSearchParams();
  const go = useGo();
  const { token } = theme.useToken();

  const { current, gotoStep, stepsProps, formProps, saveButtonProps } =
    useStepsForm<IVehicle>();

  const { formList } = useFormList({ formProps });

  const handleModalClose = () => {
    go({
      to:
        searchParams.get("to") ??
        getToPath({
          action: "list",
        }) ??
        "",
      query: {
        to: undefined,
      },
      options: {
        keepQuery: true,
      },
      type: "replace",
    });
  };

  const isLastStep = current === formList.length - 1;
  const isFirstStep = current === 0;

  return (
    <Modal
      open
      destroyOnClose
      maskClosable={false}
      title={t("vehicles.actions.add")}
      styles={{
        header: {
          padding: "20px 24px",
          margin: 0,
          borderBottom: `1px solid ${token.colorBorderSecondary}`,
        },
        footer: {
          padding: "20px 24px",
          margin: 0,
          borderTop: `1px solid ${token.colorBorderSecondary}`,
        },
        content: {
          padding: 0,
        },
      }}
      footer={() => {
        return (
          <Flex align="center" justify="space-between">
            <Button onClick={handleModalClose}>{t("buttons.cancel")}</Button>
            <Flex align="center" gap={16}>
              <Button
                disabled={isFirstStep}
                onClick={() => {
                  gotoStep(current - 1);
                }}
              >
                {t("buttons.previousStep")}
              </Button>
              {isLastStep ? (
                <SaveButton icon={false} {...saveButtonProps} />
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    gotoStep(current + 1);
                  }}
                >
                  {t("buttons.nextStep")}
                </Button>
              )}
            </Flex>
          </Flex>
        );
      }}
      onCancel={handleModalClose}
    >
      <Flex style={{ padding: "20px 24px" }}>
        <Steps {...stepsProps} responsive>
          <Steps.Step title={t("vehicles.steps.vehicle")} />
          <Steps.Step title={t("vehicles.steps.details")} />
        </Steps>
      </Flex>
      <Form {...formProps} layout="vertical">
        {formList[current]}
      </Form>
    </Modal>
  );
};

type UseFormListProps = {
  formProps: UseFormReturnType<IVehicle>["formProps"];
};

const useFormList = (props: UseFormListProps) => {
  const t = useTranslate();
  const organization = localStorage.getItem("organization");

  const formList = useMemo(() => {
    const step1 = (
      <Flex
        key="personal"
        vertical
        style={{
          padding: "20px 24px",
        }}
      >
        {organization ? (
          <Form.Item
            name={"organization_id"}
            initialValue={organization}
            style={{
              display: "none",
            }}
          >
            <Input value={organization} defaultValue={organization} />
          </Form.Item>
        ) : null}
        <Form.Item
          label={t("vehicles.fields.vin")}
          name="vin"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("vehicles.fields.vehicle_make")}
          name="vehicle_make"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("vehicles.fields.vehicle_model")}
          name="vehicle_model"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("vehicles.fields.vehicle_year")}
          name="vehicle_year"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
      </Flex>
    );

    const step2 = (
      <Flex
        key="company"
        vertical
        style={{
          padding: "20px 24px",
        }}
      >
        <Form.Item
          label={t("vehicles.fields.color")}
          name="color"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("vehicles.fields.capacity")}
          name="passenger_capacity"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label={t("vehicles.fields.fuel_economy")}
          name="fuel_economy"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label={t("drivers.fields.license.label")}
          name="required_license"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value="CDL">CDL</Select.Option>
            <Select.Option value="non-CDL">Non CDL</Select.Option>
          </Select>
        </Form.Item>
      </Flex>
    );

    return [step1, step2];
  }, [props.formProps]);

  return { formList };
};
