import {
  useTranslate,
  useExport,
  useGo,
  useNavigation,
  useMany,
  useList,
  getDefaultFilter,
} from "@refinedev/core";
import {
  List,
  useTable,
  FilterDropdown,
  ExportButton,
  CreateButton,
  EditButton,
  DateField,
} from "@refinedev/antd";
import { Table, Typography, theme, Input } from "antd";
import { ILocation, ISchool, IStop } from "../../interfaces";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { PaginationTotal } from "../../components";
import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";

export const SchoolList = ({ children }: PropsWithChildren) => {
  const go = useGo();
  const { pathname } = useLocation();
  const { createUrl } = useNavigation();
  const t = useTranslate();
  const { token } = theme.useToken();
  const organization = localStorage.getItem("organization");

  const { tableProps, sorters, filters } = useTable<ISchool>({
    resource: "schools",
    filters: {
      initial: [
        {
          field: "organization_id",
          operator: "eq",
          value: organization,
        },
        {
          field: "name",
          operator: "contains",
          value: "",
        },
      ],
    },
  });
  const schools = tableProps.dataSource ?? [];
  const locationIds = schools.map((item) => item.location);
  const { data: locationsData } = useMany<IStop>({
    resource: "stops",
    ids: locationIds,
    queryOptions: {
      enabled: locationIds.length > 0,
    },
  });

  const { data } = useList<IStop>({
    resource: "stops",
    pagination: {
      mode: "off",
    },
  });
  const locations = data?.data;

  const getLocation = (locationId: number): string | null => {
    const location = locations?.find((location) => location.id === locationId);
    return location ? location.address : null;
  };


  const { isLoading, triggerExport } = useExport<ISchool>({
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    mapData: (item) => {
      return {
        id: item.id,
        name: item.name,
        address: getLocation(item.location),
        start_time: item.start_time,
        end_time: item.end_time
      };
    },
  });

  return (
    <List
      breadcrumb={false}
      headerButtons={(props) => (
        <>
          <CreateButton
            {...props.createButtonProps}
            key="create"
            size="large"
            onClick={() => {
              return go({
                to: `${createUrl("schools")}`,
                query: {
                  to: pathname,
                },
                options: {
                  keepQuery: true,
                },
                type: "replace",
              });
            }}
          >
            {t("schools.actions.add")}
          </CreateButton>
          <ExportButton
            size="large"
            onClick={triggerExport}
            loading={isLoading}
          />
        </>
      )}
    >
      <Table
        {...tableProps}
        rowKey="id"
        scroll={{ x: true }}
        pagination={{
          ...tableProps.pagination,
          showTotal: (total) => (
            <PaginationTotal total={total} entityName="schools" />
          ),
        }}
      >
        <Table.Column
          key="name"
          dataIndex={"name"}
          title={t("schools.fields.name")}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? token.colorPrimary : undefined,
              }}
            />
          )}
          defaultFilteredValue={getDefaultFilter("name", filters, "contains")}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Input style={{ width: "100%" }} placeholder={"Search school"} />
            </FilterDropdown>
          )}
        />
        <Table.Column<IStop>
          title={t("schools.fields.location")}
          dataIndex="location"
          key="location"
          render={(value) => {
            const location = locationsData?.data.find(
              (location) => location?.id === value
            );

            return (
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {location?.address || "-"}
              </Typography.Text>
            );
          }}
        />
        <Table.Column
          dataIndex={"start_time"}
          title={t("schools.fields.start_time")}
        />
        <Table.Column
          dataIndex={"end_time"}
          title={t("schools.fields.end_time")}
        />
        <Table.Column
          title={t("table.actions")}
          key="actions"
          fixed="right"
          align="center"
          render={(_, record: ISchool) => {
            return (
              <EditButton
                icon={<EyeOutlined />}
                hideText
                recordItemId={record.id}
              />
            );
          }}
        />
      </Table>
      {children}
    </List>
  );
};
