import { useTranslate, useExport, useNavigation, useGo } from "@refinedev/core";

import { List, useTable, ExportButton, EditButton, CreateButton } from "@refinedev/antd";
import { Table, Typography, theme } from "antd";

import { PaginationTotal, VehicleStatus } from "../../components";
import { IVehicle } from "../../interfaces";
import { EyeOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { PropsWithChildren } from "react";

export const VehiclesList = ({ children }: PropsWithChildren) => {
  const organization = localStorage.getItem("organization");
  const { pathname } = useLocation();
  const { createUrl } = useNavigation();
  const go = useGo();

  const { tableProps } = useTable<IVehicle>({
    filters: {
      initial: [
        {
          field: "organization_id",
          operator: "eq",
          value: organization,
        },
      ],
    },
  });

  const t = useTranslate();

  const { isLoading, triggerExport } = useExport<IVehicle>({
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    mapData: (item) => {
      return {
        id: item.id,
        vin: item.vin,
        vehicle_make: item.vehicle_make,
        vehicle_model: item.vehicle_model,
        vehicle_year: item.vehicle_year,
        color: item.color,
        passenger_capacity: item.passenger_capacity,
        fuel_economy: item.fuel_economy + ' MPG',
        required_license: item.required_license
      };
    },
  });

  return (
    <List
      breadcrumb={false}
      headerButtons={(props) => (
        <>
          <CreateButton
            {...props.createButtonProps}
            key="create"
            size="large"
            onClick={() => {
              return go({
                to: `${createUrl("vehicles")}`,
                query: {
                  to: pathname,
                },
                options: {
                  keepQuery: true,
                },
                type: "replace",
              });
            }}
          >
            {t("vehicles.actions.add")}
          </CreateButton>
          <ExportButton
            size="large"
            onClick={triggerExport}
            loading={isLoading}
          />
        </>
      )}
    >
      <Table
        {...tableProps}
        rowKey="id"
        style={{
          cursor: "pointer",
        }}
        pagination={{
          ...tableProps.pagination,
          showTotal: (total) => (
            <PaginationTotal total={total} entityName="vehicles" />
          ),
        }}
      >
        <Table.Column
          key="id"
          dataIndex="id"
          title={t("vehicles.fields.id")}
          width={20}
          render={(value) => (
            <Typography.Text
              style={{
                whiteSpace: "nowrap",
              }}
            >
              #{value}
            </Typography.Text>
          )}
        />
        <Table.Column
          key="vehicle_make"
          dataIndex="vehicle_make"
          title={t("vehicles.fields.vehicle_make")}
        />
        <Table.Column
          key="vehicle_model"
          dataIndex="vehicle_model"
          title={t("vehicles.fields.vehicle_model")}
        />
        <Table.Column
          key="vehicle_year"
          dataIndex="vehicle_year"
          title={t("vehicles.fields.vehicle_year")}
        />
        <Table.Column
          key="color"
          dataIndex="color"
          title={t("vehicles.fields.color")}
        />
        <Table.Column
          key="passenger_capacity"
          dataIndex="passenger_capacity"
          title={t("vehicles.fields.capacity")}
        />
        <Table.Column
          key="fuel_economy"
          dataIndex="fuel_economy"
          title={t("vehicles.fields.fuel_economy")}
          render={(value) => {
            return (
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {value + ' MPG'}
              </Typography.Text>
            );
          }}
        />
        <Table.Column
          key="required_license"
          dataIndex="required_license"
          title={t("vehicles.fields.required_license")}
        />
        <Table.Column
          title={t("table.view")}
          key="actions"
          fixed="right"
          align="center"
          render={(_, record: IVehicle) => {
            return (
              <EditButton
                icon={<EyeOutlined />}
                hideText
                recordItemId={record.id}
              />
            );
          }}
        />
      </Table>
      {children}
    </List>
  );
};
