import { useTranslate, getDefaultFilter } from "@refinedev/core";
import {
  useTable,
  FilterDropdown,
  getDefaultSortOrder,
  EditButton,
} from "@refinedev/antd";
import { Input, InputNumber, Select, Table, Typography, theme } from "antd";
import { IStop } from "../../../interfaces";
import { PaginationTotal } from "../../../components";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { StopStatus } from "../../../components/stop";

export const StopListTable = () => {
  const { token } = theme.useToken();
  const t = useTranslate();
  const organization = localStorage.getItem("organization");

  const { tableProps, sorters, filters } = useTable<IStop>({
    resource: "stops",
    filters: {
      initial: [
        {
          field: "organization_id",
          operator: "eq",
          value: organization,
        },
        {
          field: "address",
          operator: "contains",
          value: "",
        },
      ],
    },
  });

  return (
    <Table
      {...tableProps}
      rowKey="id"
      scroll={{
        x: true,
      }}
      pagination={{
        ...tableProps.pagination,
        showTotal: (total) => (
          <PaginationTotal total={total} entityName="stops" />
        ),
      }}
    >
      <Table.Column
        dataIndex={"address"}
        title={t("stops.fields.address")}
        filterIcon={(filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? token.colorPrimary : undefined,
            }}
          />
        )}
        defaultFilteredValue={getDefaultFilter("address", filters, "contains")}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input style={{ width: "100%" }} placeholder={"Search address"} />
          </FilterDropdown>
        )}
      />
      <Table.Column dataIndex={"name"} title={t("stops.fields.name.label")} />
      <Table.Column dataIndex={"notes"} title={t("stops.fields.notes")} />
      {/* <Table.Column
        dataIndex="isActive"
        title={t("stops.fields.isActive.label")}
        sorter
        defaultSortOrder={getDefaultSortOrder("isActive", sorters)}
        defaultFilteredValue={getDefaultFilter("isActive", filters, "in")}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              style={{ width: "200px" }}
              allowClear
              mode="multiple"
              placeholder="Select status"
            >
              <Select.Option value="true">
                {t("stops.fields.isActive.true")}
              </Select.Option>
              <Select.Option value="false">
                {t("stops.fields.isActive.false")}
              </Select.Option>
            </Select>
          </FilterDropdown>
        )}
        render={(value) => <StopStatus value={value} />}
      /> */}
      <Table.Column<IStop>
        fixed="right"
        title={t("table.view")}
        dataIndex="actions"
        key="actions"
        align="center"
        render={(_, record) => (
          <EditButton
            icon={<EyeOutlined />}
            recordItemId={record.id}
            resource="stops"
            hideText
          />
        )}
      />
    </Table>
  );
};
