import { useEffect, useRef, useState } from "react";
import { useNavigation, useTranslate } from "@refinedev/core";
import {
  DeleteButton,
  ListButton,
  SaveButton,
  useForm,
  useSelect,
} from "@refinedev/antd";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  InputRef,
  Row,
  Select,
} from "antd";

import { IDriver } from "../../interfaces";
import {
  FormItemEditable,
  FormItemHorizontal,
} from "../../components";
import {
  EditOutlined,
  LeftOutlined,
  RightCircleOutlined,
  ShopOutlined,
  UserOutlined,
} from "@ant-design/icons";
// import Calendar from "../../components/calendar";

export const DriverEdit = () => {
  const titleInputRef = useRef<InputRef>(null);

  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const t = useTranslate();
  const { list } = useNavigation();
  const { formProps, queryResult, saveButtonProps } = useForm<IDriver>();
  const driver = queryResult?.data?.data;
  const organization = localStorage.getItem("organization");

  const { selectProps: locationsSelectProps } = useSelect({
    resource: "stops",
    defaultValue: driver?.home_location,
    optionLabel: "address",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    queryOptions: {
      enabled: !!driver?.home_location,
    },
  });

  useEffect(() => {
    if (!isFormDisabled) {
      titleInputRef.current?.focus();
    }
  }, [isFormDisabled]);

  return (
    <>
      <Flex>
        <ListButton icon={<LeftOutlined />}>{t("drivers.drivers")}</ListButton>
      </Flex>
      <Divider />

      <Row gutter={16}>
        <Col>
          <Form {...formProps} layout="horizontal" disabled={isFormDisabled}>
            <Flex align="center" gap={24}>
              <Avatar size="large" icon={<UserOutlined />} />
              <FormItemEditable
                formItemProps={{
                  name: "name",
                  style: {
                    width: "100%",
                    marginBottom: "0",
                  },
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
              >
                <Input
                  ref={titleInputRef}
                  size="large"
                  placeholder={t("drivers.fields.name.placeholder")}
                />
              </FormItemEditable>
            </Flex>
            <Card
              style={{
                marginTop: "16px",
              }}
              styles={{
                body: {
                  padding: 0,
                },
              }}
            >
              <FormItemHorizontal
                icon={<RightCircleOutlined />}
                label={t("drivers.fields.license.label")}
                name="license"
              >
                <Select placeholder={t("drivers.fields.license.placeholder")}>
                  <Select.Option value="CDL">CDL</Select.Option>
                  <Select.Option value="non-CDL">Non CDL</Select.Option>
                </Select>
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<ShopOutlined />}
                label={t("drivers.fields.address.label")}
                name="home_location"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select {...locationsSelectProps} />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
            </Card>
            <Flex
              align="center"
              justify="space-between"
              style={{
                padding: "16px 16px 0px 16px",
              }}
            >
              {isFormDisabled ? (
                <>
                  {/* <DeleteButton
                    type="text"
                    onSuccess={() => {
                      list("drivers");
                    }}
                    style={{
                      marginLeft: "-16px",
                    }}
                  /> */}
                  <Button
                    style={{
                      marginLeft: "auto",
                    }}
                    disabled={false}
                    icon={<EditOutlined />}
                    onClick={() => setIsFormDisabled(false)}
                  >
                    {t("actions.edit")}
                  </Button>
                </>
              ) : (
                <>
                  <Button onClick={() => setIsFormDisabled(true)}>
                    {t("actions.cancel")}
                  </Button>
                  <SaveButton
                    {...saveButtonProps}
                    disabled={isFormDisabled}
                    style={{
                      marginLeft: "auto",
                    }}
                    htmlType="submit"
                    type="primary"
                    icon={null}
                  >
                    Save
                  </SaveButton>
                </>
              )}
            </Flex>
          </Form>
        </Col>
        {/* <Col
          span={15}
          style={{
            marginTop: "60px",
          }}
        >
          <Calendar />
        </Col> */}
      </Row>
    </>
  );
};
