import { useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useList } from "@refinedev/core";
import { IStop } from "../../../interfaces";
import { Flex } from "antd";

export const AllStopsMap = () => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [coordinatesList, setCoordinatesList] = useState<{ id: number; location: google.maps.LatLng }[][]>([]);
  const organization = localStorage.getItem("organization");

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_APP_MAP_ID ?? "",
  });

  const { data: stopsData } = useList<IStop>({
    resource: "stops",
    pagination: {
      mode: "off",
    },
    filters:  [
        {
          field: "organization_id",
          operator: "eq",
          value: organization,
        },
      ],
  });
  const stops = stopsData?.data || [];

  useEffect(() => {
    if (!isLoaded || stops.length === 0) return;

    const geocoder = new google.maps.Geocoder();

    Promise.all(stops.map(stop =>
      new Promise<{ id: number; location: google.maps.LatLng }>((resolve, reject) => {
        geocoder.geocode({ address: stop.address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
            resolve({ id: stop.id, location: results[0].geometry.location });
          } else {
            reject(`Geocode failed for address: ${stop.address} with status: ${status}`);
          }
        });
      })
    ))
    .then(results => {
      // Transform results into the correct format for coordinatesList
      const updatedCoordinatesList: { id: number; location: google.maps.LatLng }[][] = [
        ...coordinatesList,  // Include previous state
        results  // Add new results
      ];
      setCoordinatesList(updatedCoordinatesList);
    })
    .catch(error => {
      console.error(error);
    });

  }, [isLoaded, stops]);

  return (
    <Flex
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={{ lat: 42.9552965, lng: -85.5780721 }}
          zoom={6}
          onLoad={map => {
            mapRef.current = map as google.maps.Map;
          }}
        >
          {coordinatesList.flat().map((coord, index) => (
            <Marker 
              key={coord.id} 
              position={coord.location}
            />
          ))}
        </GoogleMap>
      )}
    </Flex>
  );
};
