import { useEffect, useRef, useState } from "react";
import { useNavigation, useTranslate } from "@refinedev/core";
import {
  DeleteButton,
  ListButton,
  SaveButton,
  useForm,
  useSelect,
} from "@refinedev/antd";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  InputRef,
  Row,
  Select,
  theme,
} from "antd";

import { IStudent } from "../../interfaces";
import {
  CardWithContent,
  FormItemEditable,
  FormItemHorizontal,
} from "../../components";
import {
  BookOutlined,
  ContactsOutlined,
  EditOutlined,
  LeftOutlined,
  PhoneOutlined,
  RightCircleOutlined,
  ShopOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import InputMask from "react-input-mask";

export const StudentEdit = () => {
  const titleInputRef = useRef<InputRef>(null);

  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const t = useTranslate();
  const { list } = useNavigation();
  const { formProps, queryResult, saveButtonProps } = useForm<IStudent>();
  const student = queryResult?.data?.data;
  const organization = localStorage.getItem("organization");
  const { token } = theme.useToken();

  const { selectProps: schoolSelectProps } = useSelect({
    resource: "schools",
    defaultValue: student?.school,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    queryOptions: {
      enabled: !!student?.school,
    },
  });

  const { selectProps: locationsSelectProps } = useSelect({
    resource: "stops",
    optionLabel: "address",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  useEffect(() => {
    if (!isFormDisabled) {
      titleInputRef.current?.focus();
    }
  }, [isFormDisabled]);

  return (
    <>
      <Flex>
        <ListButton icon={<LeftOutlined />}>
          {t("students.students")}
        </ListButton>
      </Flex>
      <Divider />

      <Row gutter={16}>
        <Col>
          <Form {...formProps} layout="horizontal" disabled={isFormDisabled}>
            <Flex align="center" gap={24}>
              <Avatar size="large" icon={<UserOutlined />} />
              <FormItemEditable
                formItemProps={{
                  name: "name",
                  style: {
                    width: "100%",
                    marginBottom: "0",
                  },
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
              >
                <Input
                  ref={titleInputRef}
                  size="large"
                  placeholder={t("students.fields.name.placeholder")}
                />
              </FormItemEditable>
            </Flex>
            <Card
              style={{
                marginTop: "16px",
              }}
              styles={{
                body: {
                  padding: 0,
                },
              }}
            >
              <FormItemHorizontal
                icon={<BookOutlined />}
                label={t("students.fields.school")}
                name="school"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select {...schoolSelectProps} />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<RightCircleOutlined />}
                label={t("students.fields.grade")}
                name="grade"
              >
                <InputNumber />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<ShopOutlined />}
                label={t("students.fields.address")}
                name="home_location"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select {...locationsSelectProps} />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<ContactsOutlined />}
                label={t("students.fields.contact_name")}
                name="contact_name"
              >
                <Input />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                name="contact_number"
                icon={<PhoneOutlined />}
                label={t("students.fields.contact_number")}
              >
                <InputMask mask="999-999-9999">
                  {/* 
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore */}
                  {(props: InputProps) => <Input {...props} />}
                </InputMask>
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<ContactsOutlined />}
                label={t("students.fields.notes")}
                name="notes"
              >
                <Input.TextArea rows={2} />
              </FormItemHorizontal>
            </Card>
            <Flex
              align="center"
              justify="space-between"
              style={{
                padding: "16px 16px 0px 16px",
              }}
            >
              {isFormDisabled ? (
                <>
                  <DeleteButton
                    type="text"
                    onSuccess={() => {
                      list("students");
                    }}
                    style={{
                      marginLeft: "-16px",
                    }}
                  />
                  <Button
                    style={{
                      marginLeft: "auto",
                    }}
                    disabled={false}
                    icon={<EditOutlined />}
                    onClick={() => setIsFormDisabled(false)}
                  >
                    {t("actions.edit")}
                  </Button>
                </>
              ) : (
                <>
                  <Button onClick={() => setIsFormDisabled(true)}>
                    {t("actions.cancel")}
                  </Button>
                  <SaveButton
                    {...saveButtonProps}
                    disabled={isFormDisabled}
                    style={{
                      marginLeft: "auto",
                    }}
                    htmlType="submit"
                    type="primary"
                    icon={null}
                  >
                    Save
                  </SaveButton>
                </>
              )}
            </Flex>
          </Form>
        </Col>
        {/* <Col
          span={14}
          style={{
            marginTop: "60px",
          }}
        >
          <CardWithContent
            bodyStyles={{
              height: "400px",
              overflow: "hidden",
            }}
            icon={
              <ShoppingOutlined
                style={{
                  fontSize: 14,
                  color: token.colorPrimary,
                }}
              />
            }
            title={t("dashboard.recentOrders.title")}
          ></CardWithContent>
        </Col> */}
      </Row>
    </>
  );
};
