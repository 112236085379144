import { useState, useEffect } from "react";
import { useGetIdentity, useTranslate, useList } from "@refinedev/core";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import {
  Input,
  Avatar,
  Typography,
  Space,
  Grid,
  Row,
  Col,
  AutoComplete,
  Layout as AntdLayout,
  Button,
  theme,
} from "antd";
import debounce from "lodash/debounce";
import { useConfigProvider } from "../../context";
import { IconMoon, IconSun } from "../../components/icons";
import { IIdentity, IUser } from "../../interfaces";
import { useStyles } from "./styled";
import { CurrentUser } from "./current-user";

const { Header: AntdHeader } = AntdLayout;
const { useToken } = theme;
const { Text } = Typography;
const { useBreakpoint } = Grid;

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}

interface IOptions {
  label: string | React.ReactNode;
  options: IOptionGroup[];
}

export const Header: React.FC = () => {
  const { token } = useToken();
  const { styles } = useStyles();
  const { mode, setMode } = useConfigProvider();
  const { data: user } = useGetIdentity<IIdentity>();
  const screens = useBreakpoint();
  const t = useTranslate();

  const { data: profile } = useList<IUser>({
    resource: "profiles",
    filters: [
      {
        field: "id",
        operator: "eq",
        value: user?.id,
      },
    ],
  });
  if (profile) {
    localStorage.setItem("organization", profile?.data[0].organization_id);
  }

  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    setOptions([]);
  }, [value]);

  return (
    <AntdHeader
      style={{
        backgroundColor: token.colorBgElevated,
        padding: "0 24px",
      }}
    >
      <Row
        align="middle"
        style={{
          justifyContent: screens.sm ? "space-between" : "end",
        }}
      >
        <Col xs={0} sm={8} md={12}>
          {/* <AutoComplete
            style={{
              width: "100%",
              maxWidth: "550px",
            }}
            options={options}
            disabled
            filterOption={false}
            onSearch={debounce((value: string) => setValue(value), 300)}
          >
            <Input
              size="large"
              placeholder={t("search.placeholder")}
              suffix={<div className={styles.inputSuffix}>/</div>}
              prefix={<SearchOutlined className={styles.inputPrefix} />}
            />
          </AutoComplete> */}
        </Col>
        <Col>
          <Space size={screens.md ? 32 : 16} align="center">
            <Button
              className={styles.themeSwitch}
              type="text"
              icon={mode === "light" ? <IconMoon /> : <IconSun />}
              onClick={() => {
                setMode(mode === "light" ? "dark" : "light");
              }}
            />

            <Space size={screens.md ? 16 : 8} align="center">
              <CurrentUser />
            </Space>
          </Space>
        </Col>
      </Row>
    </AntdHeader>
  );
};
