import { Tag, Typography, theme } from "antd";
import { CheckCircleOutlined, StopOutlined, SyncOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { useConfigProvider } from "../../../context";

type Props = {
  value: "needs_service" | "safe" | "critical_damage";
};

export const SeverityLevel = ({ value }: Props) => {
  const t = useTranslate();
  const { token } = theme.useToken();
  const { mode } = useConfigProvider();
  const isDark = mode === "dark";

  // Determine color, icon, and text color based on the status value
  let tagColor = "default";
  let textColor = token.colorTextTertiary;
  let icon = <StopOutlined />;

  if (value === "safe") {
    tagColor = "green";
    textColor = isDark ? token.green7 : "#3C8618";
    icon = <CheckCircleOutlined />;
  } else if (value === "needs_service") {
    tagColor = "blue";
    textColor = isDark ? token.blue7 : "#1677FF";
    icon = <SyncOutlined spin />;
  } else if (value === "critical_damage") {
    tagColor = "red";
    textColor = isDark ? token.red7 : "#CF1322";
    icon = <StopOutlined />;
  }

  return (
    <Tag
      color={tagColor}
      style={{ marginInlineEnd: 0 }}
      icon={icon}
    >
      <Typography.Text
        style={{ color: textColor }}
      >
        {t(`reports.fields.status.${value}`)}
      </Typography.Text>
    </Tag>
  );
};
