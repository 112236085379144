import { useEffect, useRef, useState } from "react";
import {
  UseFormProps,
  useGo,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import {
  DeleteButton,
  ListButton,
  SaveButton,
  UseFormReturnType,
  useSelect,
} from "@refinedev/antd";
import {
  Form,
  Input,
  Segmented,
  Card,
  Flex,
  Divider,
  Button,
  InputRef,
  Select,
  TimePicker,
  DatePicker,
} from "antd";
import _debounce from "lodash/debounce";
import { IDriver, IRoute, IVehicle } from "../../../interfaces";
import { useStyles } from "./styled";
import {
  EditOutlined,
  PlusOutlined,
  RightCircleOutlined,
  RocketOutlined,
  SettingOutlined,
  SlidersOutlined,
  UserAddOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { FormItemEditable, FormItemHorizontal } from "../../form";
import { RoutesStatus } from "../status";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { SportsStatus } from "../sports";
dayjs.extend(customParseFormat);

type Props = {
  formProps: UseFormReturnType<IRoute>["formProps"];
  saveButtonProps: UseFormReturnType<IRoute>["saveButtonProps"];
  action: UseFormProps["action"];
  isFormDisabled: boolean;
  setIsFormDisabled: (value: boolean) => void;
  handleChange: (time: string | string[]) => void;
};

export const RouteFormFields = ({
  formProps,
  saveButtonProps,
  action,
  isFormDisabled,
  setIsFormDisabled,
  handleChange,
}: Props) => {
  const titleInputRef = useRef<InputRef>(null);
  const go = useGo();
  const param = useParams();
  const { createUrl } = useNavigation();
  const t = useTranslate();
  const { list } = useNavigation();
  const { styles } = useStyles();
  const organization = localStorage.getItem("organization");
  const [showWeekdays, setShowWeekdays] = useState<boolean>(false);
  const defaultSelectedValues = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
  ];

  const onChange = (time: any, timeString: string | string[]) => {
    handleChange(timeString);
  };

  const handleChangeWeekdays = (value: string) => {
    if (value === "one-time") {
      setShowWeekdays(false);
      formProps.form?.setFieldValue("weekdays", null);
    } else if (value === "weekday") {
      setShowWeekdays(false);
      formProps.form?.setFieldValue("weekdays", [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
      ]);
    } else if (value === "daily") {
      setShowWeekdays(false);
      formProps.form?.setFieldValue("weekdays", [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ]);
    } else {
      setShowWeekdays(true);
      formProps.form?.setFieldValue("weekdays", null);
    }
  };

  const { selectProps: driverSelectProps } = useSelect<IDriver>({
    resource: "drivers",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  const { selectProps: vehicleSelectProps } = useSelect<IVehicle>({
    resource: "vehicles",
    optionLabel: "vehicle_model",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  useEffect(() => {
    if (!isFormDisabled) {
      titleInputRef.current?.focus();
    }
  }, [isFormDisabled]);

  const statusField = Form.useWatch("isActive", formProps.form);
  const sportsField = Form.useWatch("sports", formProps.form);

  return (
    <Form {...formProps} layout="horizontal" disabled={isFormDisabled}>
      <Card
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <FormItemHorizontal
          icon={<SettingOutlined />}
          label={t("routes.fields.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </FormItemHorizontal>
        <Divider
          style={{
            margin: 0,
          }}
        />
        <FormItemHorizontal
          name="isActive"
          initialValue={true}
          icon={<RightCircleOutlined />}
          label={t("routes.fields.isActive.label")}
        >
          {isFormDisabled ? (
            <RoutesStatus value={statusField} />
          ) : (
            <Segmented
              options={[
                {
                  label: t("routes.fields.isActive.true"),
                  value: true,
                },
                {
                  label: t("routes.fields.isActive.false"),
                  value: false,
                },
              ]}
            />
          )}
        </FormItemHorizontal>
        <Divider
          style={{
            margin: 0,
          }}
        />
        <FormItemHorizontal
          icon={<UserAddOutlined />}
          label={t("routes.fields.vehicles")}
          name="vehicle"
          className={styles.formItem}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...vehicleSelectProps} />
        </FormItemHorizontal>
        <Divider
          style={{
            margin: 0,
          }}
        />
        {organization ? (
          <Form.Item
            name={"organization_id"}
            initialValue={organization}
            style={{
              display: "none",
            }}
          >
            <Input value={organization} defaultValue={organization} />
          </Form.Item>
        ) : null}
        <Form.Item
          name={"stop_list"}
          initialValue={[]}
          style={{
            display: "none",
          }}
        >
          <Input value={[]} defaultValue={[]} />
        </Form.Item>
        <Divider
          style={{
            margin: 0,
          }}
        />
        <FormItemHorizontal
          icon={<UserAddOutlined />}
          label={t("routes.fields.drivers")}
          name="drivers"
          className={styles.formItem}
        >
          <Select mode="multiple" {...driverSelectProps} />
        </FormItemHorizontal>
        <Divider
          style={{
            margin: 0,
          }}
        />
        <FormItemHorizontal
          icon={<SlidersOutlined />}
          label={t("routes.fields.vehicle_type")}
          name="vehicle_type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value="car">Car</Select.Option>
            <Select.Option value="van">Van</Select.Option>
            <Select.Option value="bus">Bus</Select.Option>
          </Select>
        </FormItemHorizontal>
        <Divider
          style={{
            margin: 0,
          }}
        />
        <FormItemHorizontal
          icon={<SlidersOutlined />}
          label={t("routes.fields.frequency")}
        >
          <Select
            defaultValue={"weekday"}
            onChange={handleChangeWeekdays}
            options={[
              { value: "one-time", label: "One-time (Does not repeat)" },
              { value: "weekday", label: "Every weekday (Monday to Friday)" },
              { value: "daily", label: "Daily (Everyday)" },
              { value: "custom", label: "Custom" },
            ]}
          />
        </FormItemHorizontal>
        <div style={{ display: showWeekdays ? "block" : "none" }}>
            <Divider
              style={{
                margin: 0,
              }}
            />
            <FormItemHorizontal
              icon={<SlidersOutlined />}
              label={t("routes.fields.weekdays")}
              name="weekdays"
              initialValue={defaultSelectedValues}
            >
              <Select
                mode="multiple"
                // defaultValue={defaultSelectedValues}
                options={[
                  { value: "monday", label: "Monday" },
                  { value: "tuesday", label: "Tuesday" },
                  { value: "wednesday", label: "Wednesday" },
                  { value: "thursday", label: "Thursday" },
                  { value: "friday", label: "Friday" },
                  { value: "saturday", label: "Saturday" },
                  { value: "sunday", label: "Sunday" },
                ]}
              />
            </FormItemHorizontal>
        </div>
        <Divider
          style={{
            margin: 0,
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.5rem",
          }}
        >
          <FormItemHorizontal
            icon={<CalendarOutlined />}
            label={t("routes.fields.date")}
            name="start_date"
            initialValue={[dayjs()]}
          >
            <DatePicker format={"YYYY/MM/DD"} />
          </FormItemHorizontal>
          <FormItemHorizontal
            icon={<FieldTimeOutlined />}
            label={t("routes.fields.time")}
            initialValue={"09:00 AM"}
          >
            <TimePicker use12Hours defaultValue={dayjs("09:00 AM", "hh:mm A")} format="hh:mm A" onChange={onChange} />
          </FormItemHorizontal>
          <Form.Item
            style={{ display: "none" }}
            name="start_time"
            initialValue={"09:00 AM"}
            rules={[
              {
                required: true,
              },
            ]}
          ></Form.Item>
        </div>
        <Divider
          style={{
            margin: 0,
          }}
        />
        <FormItemHorizontal
          name="sports"
          initialValue={false}
          icon={<RocketOutlined />}
          label={t("routes.fields.sports.label")}
        >
          {isFormDisabled ? (
            <SportsStatus value={sportsField} />
          ) : (
            <Segmented
              options={[
                {
                  label: t("routes.fields.sports.true"),
                  value: true,
                },
                {
                  label: t("routes.fields.sports.false"),
                  value: false,
                },
              ]}
            />
          )}
        </FormItemHorizontal>
      </Card>
      <Flex align="center" justify="end" gap={5} style={{ marginTop: "10px" }}>
        {action === "create" && (
          <>
            <ListButton icon={false}>{t("actions.cancel")}</ListButton>
            <SaveButton
              {...saveButtonProps}
              htmlType="submit"
              type="primary"
              icon={null}
            >
              Save
            </SaveButton>
          </>
        )}
        {action === "edit" &&
          (isFormDisabled ? (
            <>
              <DeleteButton
                onSuccess={() => {
                  list("routes");
                }}
                style={{
                  marginLeft: "-16px",
                }}
              />
              <Button
                disabled={false}
                icon={<EditOutlined />}
                onClick={() => setIsFormDisabled(false)}
              >
                {t("actions.edit")}
              </Button>
              <Button
                type="primary"
                disabled={false}
                icon={<PlusOutlined />}
                onClick={() => {
                  return go({
                    to: `${createUrl("stops")}`,
                    query: {
                      route: param.id,
                    },
                    // options: {
                    //   keepQuery: true,
                    // },
                    type: "replace",
                  });
                }}
              >
                {t("actions.add")}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => setIsFormDisabled(true)}>
                {t("actions.cancel")}
              </Button>
              <SaveButton
                {...saveButtonProps}
                htmlType="submit"
                type="primary"
                icon={null}
              >
                Save
              </SaveButton>
            </>
          ))}
      </Flex>
    </Form>
  );
};
