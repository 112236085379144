import React from "react";
import { Action, Authenticated, IResourceItem, Refine } from "@refinedev/core";
import { RefineKbarProvider } from "@refinedev/kbar";
import { ThemedLayoutV2, ErrorComponent, ThemedSiderV2 } from "@refinedev/antd";
import routerProvider, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
  DocumentTitleHandler,
} from "@refinedev/react-router-v6";
import { useNotificationProvider } from "@refinedev/antd";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { authProvider } from "./authProvider";
import "dayjs/locale/de";
import { DashboardPage } from "./pages/dashboard";
import { RoutesCreate, RoutesEdit, RoutesList } from "./pages/routes";
import { StudentCreate, StudentEdit, StudentList } from "./pages/students";
import { AuthPage } from "./pages/auth";
import { useTranslation } from "react-i18next";
import { Header, Title } from "./components";
import { ConfigProvider } from "./context";
import { supabaseClient } from "../src/utils";
import { dataProvider, liveProvider } from "@refinedev/supabase";
import "@refinedev/antd/dist/reset.css";
import { DriverCreate, DriverEdit, DriverList } from "./pages/drivers";
import { VehiclesList, VehicleCreate, VehicleEdit } from "./pages/vehicles";
import { StopsCreate, StopsEdit, StopsList } from "./pages/stops";
import { SchoolCreate, SchoolEdit, SchoolList } from "./pages/schools";
import { TripsList } from "./pages/trips";
import { ReportsList } from "./pages/reports";
import { resources } from "./config";

const App: React.FC = () => {
  // This hook is used to automatically login the user.
  // We use this hook to skip the login page and demonstrate the application more quickly.
  // const { loading } = useAutoLoginForDemo();

  // const API_URL = "https://api.finefoods.refine.dev";
  // const dataProvider = jsonServerDataProvider(API_URL);

  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const titleHandler = ({
    resource,
    action,
    params,
  }: {
    resource?: IResourceItem;
    action?: Action;
    params?: Record<string, string | undefined>;
  }): string => {
    let title = "Fleet Lab Web Admin App";

    if (resource && action && params) {
      title = `${resource.name} | Fleet Lab Web Admin App`;
    }
    return title;
  };

  // if (loading) {
  //   return null;
  // }

  return (
    <BrowserRouter>
      <ConfigProvider>
        <RefineKbarProvider>
          <Refine
            notificationProvider={useNotificationProvider}
            routerProvider={routerProvider}
            dataProvider={dataProvider(supabaseClient)}
            liveProvider={liveProvider(supabaseClient)}
            // dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            options={{
              liveMode: "off",
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
            // notificationProvider={useNotificationProvider}
            resources={resources}
          >
            <Routes>
              <Route
                element={
                  <Authenticated
                    key="authenticated-routes"
                    fallback={<CatchAllNavigate to="/login" />}
                  >
                    <ThemedLayoutV2
                      Header={Header}
                      Sider={() => (
                        <ThemedSiderV2
                          Title={({ collapsed }) => (
                            <Title collapsed={collapsed} />
                          )}
                          render={({ items }) => {
                            return <>{items}</>;
                          }}
                        />
                      )}
                    >
                      <div
                        style={{
                          maxWidth: "1400px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Outlet />
                      </div>
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<DashboardPage />} />

                <Route path="/routes">
                  <Route index element={<RoutesList />} />
                  <Route path=":id/edit" element={<RoutesEdit />} />
                  <Route path="new" element={<RoutesCreate />} />
                </Route>

                <Route path="/stops">
                  <Route index element={<StopsList />} />
                  <Route path="new" element={<StopsCreate />} />
                  <Route path=":id/edit" element={<StopsEdit />} />
                </Route>

                <Route path="/trips">
                  <Route index element={<TripsList />} />
                </Route>

                <Route path="/reports">
                  <Route index element={<ReportsList />} />
                </Route>

                <Route path="/schools">
                  <Route
                    path=""
                    element={
                      <SchoolList>
                        <Outlet />
                      </SchoolList>
                    }
                  >
                    <Route path="new" element={<SchoolCreate />} />
                  </Route>

                  <Route path=":id/edit" element={<SchoolEdit />} />
                </Route>

                <Route path="/students">
                  <Route
                    path=""
                    element={
                      <StudentList>
                        <Outlet />
                      </StudentList>
                    }
                  >
                    <Route path="new" element={<StudentCreate />} />
                  </Route>

                  <Route path=":id/edit" element={<StudentEdit />} />
                </Route>

                <Route path="/drivers">
                  <Route
                    path=""
                    element={
                      <DriverList>
                        <Outlet />
                      </DriverList>
                    }
                  >
                    <Route path="new" element={<DriverCreate />} />
                  </Route>

                  <Route path=":id/edit" element={<DriverEdit />} />
                </Route>

                <Route path="/vehicles">
                  <Route
                    path=""
                    element={
                      <VehiclesList>
                        <Outlet />
                      </VehiclesList>
                    }
                  >
                    <Route path="new" element={<VehicleCreate />} />
                  </Route>

                  <Route path=":id/edit" element={<VehicleEdit />} />
                </Route>
              </Route>

              <Route
                element={
                  <Authenticated key="auth-pages" fallback={<Outlet />}>
                    <NavigateToResource resource="dashboard" />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<AuthPage type="login" />} />
                <Route
                  path="/register"
                  element={<AuthPage type="register" />}
                />
                <Route
                  path="/forgot-password"
                  element={<AuthPage type="forgotPassword" />}
                />
                <Route
                  path="/update-password"
                  element={<AuthPage type="updatePassword" />}
                />
              </Route>

              <Route
                element={
                  <Authenticated key="catch-all">
                    <ThemedLayoutV2 Header={Header} Title={Title}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
            <DocumentTitleHandler handler={titleHandler} />
          </Refine>
        </RefineKbarProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
