import { IStop } from "../../interfaces";

interface ProcessedStops {
    origin: string;
    destination: string;
    waypoints: google.maps.DirectionsWaypoint[];
  }
  
  export const processStops = (stops: IStop[]): ProcessedStops | null => {
    if (stops.length < 2) {
      return null;
    }
  
    const origin = stops[0].address;
    const destination = stops[stops.length - 1].address;
    const waypoints = stops.slice(1, -1).map(stop => ({
      location: stop.address,
      stopover: true
    }));
  
    return { origin, destination, waypoints };
  };
  