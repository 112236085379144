import { useTranslate, useGetToPath, useGo } from "@refinedev/core";
import {
  SaveButton,
  useStepsForm,
  useSelect,
  UseFormReturnType,
} from "@refinedev/antd";
import {
  Form,
  Select,
  Input,
  Button,
  Steps,
  Modal,
  Flex,
  theme,
  Typography,
  Image,
  Alert,
} from "antd";
import { IDriver, IStop } from "../../interfaces";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { supabaseClient } from "../../utils/supabaseClient";
import { useLink } from "@refinedev/core";
import emailjs from "emailjs-com";
import InputMask from "react-input-mask";

export const DriverCreate = () => {
  const t = useTranslate();
  const getToPath = useGetToPath();
  const [searchParams] = useSearchParams();
  const go = useGo();
  const { token } = theme.useToken();
  const organization = localStorage.getItem("organization");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { current, gotoStep, stepsProps, formProps, saveButtonProps } =
    useStepsForm<IDriver>();

  const { formList } = useFormList({ formProps, errorMessage });

  const handleModalClose = () => {
    go({
      to:
        searchParams.get("to") ??
        getToPath({
          action: "list",
        }) ??
        "",
      query: {
        to: undefined,
      },
      options: {
        keepQuery: true,
      },
      type: "replace",
    });
  };

  const sendEmail = async (): Promise<void> => {
    const name = formProps.form?.getFieldValue("name") as string;
    const email = formProps.form?.getFieldValue("email") as string;
    const password = formProps.form?.getFieldValue("password") as string;

    try {
      const response = await emailjs.send(
        "service_t6savs8",
        "template_p0eibzh",
        {
          name,
          email,
          password,
        },
        "uTx_zRzNYdOhZgpio"
      );

      console.log("Email sent successfully:", response);
    } catch (error) {
      console.error("Failed to send email:", error);
    }
  };

  const onFinish = async () => {
    try {
      const { data, error } = await supabaseClient.auth.signUp({
        email: formProps.form?.getFieldValue("email"),
        password: formProps.form?.getFieldValue("password"),
      });

      if (error) {
        setErrorMessage(error.message);
        return {
          success: false,
          error,
        };
      }

      if (data) {
        const { data: response, error } = await supabaseClient
          .from("profiles")
          .update({
            roles: ["driver"],
            organization_id: organization,
            user_phone: formProps.form?.getFieldValue("user_phone"),
          })
          .eq("id", data.user?.id)
          .select();
        if (response) {
          formProps.onFinish?.({
            id: data.user?.id,
            name: formProps.form?.getFieldValue("name"),
            organization_id: organization,
            home_location: formProps.form?.getFieldValue("home_location"),
            license: formProps.form?.getFieldValue("license"),
          });
          sendEmail();
          return {
            success: true,
          };
        }

        if (error) {
          setErrorMessage(error.message);
          return {
            success: false,
            error,
          };
        }
      }
    } catch (error) {
      setErrorMessage("Form validation failed " + error);
      console.error("Form validation failed:", error);
    }
  };

  const isLastStep = current === formList.length - 1;
  const isFirstStep = current === 0;

  return (
    <Modal
      open
      destroyOnClose
      maskClosable={false}
      title={t("drivers.actions.add")}
      styles={{
        header: {
          padding: "20px 24px",
          margin: 0,
          borderBottom: `1px solid ${token.colorBorderSecondary}`,
        },
        footer: {
          padding: "20px 24px",
          margin: 0,
          borderTop: `1px solid ${token.colorBorderSecondary}`,
        },
        content: {
          padding: 0,
        },
      }}
      footer={() => {
        return (
          <Flex align="center" justify="space-between">
            <Button onClick={handleModalClose}>{t("buttons.cancel")}</Button>
            <Flex align="center" gap={16}>
              <Button
                disabled={isFirstStep}
                onClick={() => {
                  gotoStep(current - 1);
                }}
              >
                {t("buttons.previousStep")}
              </Button>
              {isLastStep ? (
                <SaveButton
                  icon={false}
                  {...saveButtonProps}
                  onClick={onFinish}
                />
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    gotoStep(current + 1);
                  }}
                >
                  {t("buttons.nextStep")}
                </Button>
              )}
            </Flex>
          </Flex>
        );
      }}
      onCancel={handleModalClose}
    >
      <Flex style={{ padding: "20px 24px" }}>
        <Steps {...stepsProps} responsive>
          <Steps.Step title={t("drivers.steps.details")} />
          <Steps.Step title={t("drivers.steps.create")} />
        </Steps>
      </Flex>
      <Form {...formProps} layout="vertical">
        {formList[current]}
      </Form>
    </Modal>
  );
};

type UseFormListProps = {
  formProps: UseFormReturnType<IDriver>["formProps"];
  errorMessage: string;
};

const useFormList = (props: UseFormListProps) => {
  const t = useTranslate();
  const organization = localStorage.getItem("organization");
  const Link = useLink();

  const { selectProps: locationSelectProps } = useSelect<IStop>({
    resource: "stops",
    optionLabel: "address",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  const formList = useMemo(() => {
    const step1 = (
      <Flex
        key="details"
        vertical
        style={{
          padding: "20px 24px",
        }}
      >
        <Form.Item
          label={t("drivers.fields.id.label")}
          style={{
            display: "none",
          }}
          name="id"
          initialValue={uuidv4()} // Generate a random UUID
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label={t("drivers.fields.name.label")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t("drivers.fields.name.placeholder")} />
        </Form.Item>
        <Form.Item
          label={t("drivers.fields.email.label")}
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t("drivers.fields.email.placeholder")} />
        </Form.Item>
        <Form.Item
          label={t("drivers.fields.address.label")}
          name="home_location"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Select
            {...locationSelectProps}
            placeholder={t("drivers.fields.address.placeholder")}
          />
        </Form.Item>
        <Form.Item
          label={t("drivers.fields.phone.label")}
          name="user_phone"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <InputMask mask="999-999-9999">
            {/* 
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore */}
            {(props: InputProps) => (
              <Input
                {...props}
                placeholder={t("drivers.fields.phone.placeholder")}
              />
            )}
          </InputMask>
        </Form.Item>
        <Form.Item
          label={t("drivers.fields.license.label")}
          name="license"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder={t("drivers.fields.license.placeholder")}>
            <Select.Option value="CDL">CDL</Select.Option>
            <Select.Option value="non-CDL">Non CDL</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t("drivers.fields.password.label")}
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            type="password"
            placeholder={t("drivers.fields.password.placeholder")}
          />
        </Form.Item>
      </Flex>
    );

    const step2 = (
      <Flex
        vertical
        style={{
          padding: "20px 24px",
        }}
      >
        <Typography.Paragraph>
          This action will create a new driver account for use in the Driver App
          with the following information:
        </Typography.Paragraph>
        <Typography.Paragraph style={{ marginBottom: 0 }}>
          <strong>Email:</strong> {props.formProps.form?.getFieldValue("email")}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Password:</strong>{" "}
          {props.formProps.form?.getFieldValue("password")}
        </Typography.Paragraph>
        <Typography.Paragraph>
          An email will be sent to the driver with instructions on how to
          download the Driver App and access their account.
        </Typography.Paragraph>
        <Flex justify="center" gap="16px" style={{ marginBottom: 10 }}>
          <Link
            to="https://apps.apple.com/us/app/fleet-lab-driver/id6608982748"
            target="_blank"
          >
            <Image
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              alt="Download on the App Store"
              preview={false}
              width={150}
              height={40}
            />
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=com.fleetlab"
            target="_blank"
          >
            <Image
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Download on the Play Store"
              preview={false}
              width={150}
              height={40}
            />
          </Link>
        </Flex>
        {props.errorMessage && (
          <Alert message={props.errorMessage} type="error" closable />
        )}
      </Flex>
    );

    return [step1, step2];
  }, [props.formProps]);

  return { formList };
};
