import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, DirectionsService, DirectionsRenderer, useJsApiLoader, Marker } from '@react-google-maps/api';
import { IRoute, IStop } from '../../interfaces';
import tinycolor from 'tinycolor2';

interface MultiRouteMapComponentProps {
  routes: IRoute[];
}

const generateColor = (baseColor: string | tinycolor.ColorFormats.PRGB | tinycolor.ColorFormats.RGB | tinycolor.ColorFormats.HSL | tinycolor.ColorFormats.HSV | tinycolor.Instance | undefined, index: number) => {
    return tinycolor(baseColor).spin(index * 30).toHexString();
  };

const MultiRouteMapComponent: React.FC<MultiRouteMapComponentProps> = ({ routes }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_APP_MAP_ID ?? "",
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [directionsResults, setDirectionsResults] = useState<google.maps.DirectionsResult[]>([]);
  const [coordinatesList, setCoordinatesList] = useState<{ id: number; location: google.maps.LatLng }[][]>([]);

  useEffect(() => {
    if (!isLoaded || routes.length === 0) return;

    const geocoder = new google.maps.Geocoder();
    
    const geocodePromises = routes.map(route =>
      Promise.all(route.stop_list.map((stop: IStop) =>
        new Promise<{ id: number; location: google.maps.LatLng }>((resolve, reject) => {
          geocoder.geocode({ address: stop.address }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
              resolve({ id: stop.id, location: results[0].geometry.location });
            } else {
              reject(`Geocode failed for address: ${stop.address} with status: ${status}`);
            }
          });
        })
      ))
    );

    Promise.all(geocodePromises)
      .then(results => {
        setCoordinatesList(results);
      })
      .catch(error => {
        console.error(error);
      });
  }, [isLoaded, routes]);

  useEffect(() => {
    if (!isLoaded || coordinatesList.length === 0) return;

    const directionsService = new google.maps.DirectionsService();
    const directionsPromises = coordinatesList.map(coordinates => {
      if (coordinates.length < 2) return null;

      const waypoints = coordinates.slice(1, -1).map(coord => ({
        location: coord.location,
        stopover: true
      }));

      const origin = coordinates[0].location;
      const destination = coordinates[coordinates.length - 1].location;

      return new Promise<google.maps.DirectionsResult | null>((resolve, reject) => {
        directionsService.route(
          {
            origin: origin,
            destination: destination,
            waypoints: waypoints,
            optimizeWaypoints: true,
            travelMode: google.maps.TravelMode.DRIVING
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              resolve(result);
            } else {
              console.error(`Directions request failed due to ${status}`);
              resolve(null);
            }
          }
        );
      });
    });

    Promise.all(directionsPromises)
    .then(results => {
      setDirectionsResults(results.filter(result => result !== null) as google.maps.DirectionsResult[]);
      
      // Zoom to fit all routes
      if (mapRef.current && results.length > 0) {
        const bounds = new google.maps.LatLngBounds();
        results.forEach(result => {
          if (result) {
            const route = result.routes[0];
            route.overview_path.forEach(path => bounds.extend(path));
          }
        });
        mapRef.current.fitBounds(bounds);
      }
    })
    .catch(error => {
      console.error(error);
    });
  }, [isLoaded, coordinatesList]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const smallMarkerIcon = {
    url: "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png", // URL of the default marker icon
    scaledSize: new google.maps.Size(20, 30), // Adjust the size (default is usually around 30x30)
  };

//   const mapCenter = coordinatesList.length > 0 && coordinatesList[0].length > 0 ? coordinatesList[0][0].location : { lat: 42.9552965, lng: -85.5780721 };

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%' }}
      center={{ lat: 42.9552965, lng: -85.5780721 }}
      zoom={6}
      onLoad={map => {
        mapRef.current = map as google.maps.Map;
      }}
    >
      {directionsResults.map((directions, index) => (
        <DirectionsRenderer
          key={index}
          options={{ 
            directions: directions,
            polylineOptions: {
              strokeColor: generateColor("#1677ff", index),
              strokeOpacity: 0.8,
              strokeWeight: 6
            },
            markerOptions: {
              icon: smallMarkerIcon, // Use the scaled-down default marker
            },
          }}
        />
      ))}
    </GoogleMap>
  );
};

export default MultiRouteMapComponent;
