import {
  useGo,
  useInfiniteList,
  useList,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { DateField, NumberField } from "@refinedev/antd";
import { IDriver, IRoute } from "../../../interfaces";
import { Button, Card, Divider, Flex, List, Spin, Tag, Typography } from "antd";
import { useStyles } from "./styled";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RouteIcon from "@mui/icons-material/Route";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from "dayjs";

type Props = {
  setRouteCard: (route: IRoute) => void;
  route: IRoute | undefined;
  activeOption: boolean;
  timeOption: string;
  sportsOption: boolean;
};

interface DriverProps {
  drivers: IDriver[] | undefined;
  value: any[];
}

const Drivers: React.FC<DriverProps> = ({ drivers, value }) => {
  if (!value || value.length === 0) {
    return (
      <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <PersonIcon sx={{ color: "#1677ff" }} fontSize="small" />-
      </span>
    );
  }
  const matchedDrivers = drivers?.filter((driver: IDriver) =>
    value.includes(driver?.id)
  );

  return (
    <Typography style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <PersonIcon sx={{ color: "#1677ff" }} fontSize="small" />
      {matchedDrivers && matchedDrivers.length > 0 ? (
        <span>
          {matchedDrivers.map((driver: IDriver, index: number) => (
            <span key={driver.id}>
              {driver.name}
              {index < matchedDrivers.length - 1 && ", "}
            </span>
          ))}
        </span>
      ) : (
        <span>-</span>
      )}
    </Typography>
  );
};

export const RoutesListCard = ({
  setRouteCard,
  route,
  activeOption,
  sportsOption,
  timeOption,
}: Props) => {
  const { styles } = useStyles();
  const go = useGo();
  const { editUrl } = useNavigation();
  const t = useTranslate();
  const organization = localStorage.getItem("organization");

  const { data: dataDrivers } = useList<IDriver>({
    resource: "drivers",
    pagination: {
      mode: "off",
    },
  });
  const drivers = dataDrivers?.data;

  const {
    data: dataRoutes,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteList<IRoute>({
    resource: "routes",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
      {
        field: "isActive",
        operator: "eq",
        value: activeOption,
      },
      {
        field: "start_time",
        operator: "contains",
        value: timeOption,
      },
      {
        field: "sports",
        operator: "eq",
        value: sportsOption,
      },
    ],
  });

  const routes = dataRoutes?.pages.flatMap((page) => page.data) || [];

  // const totalPickUpStudents = (stops: any) => {
  //   return stops.reduce((total: any, stop: { pick_up_students: never[]; }) => {
  //     const pickUpStudents = stop.pick_up_students || [];
  //     return total + pickUpStudents.length;
  //   }, 0);
  // };

  return (
    <div
      id="scrollableDiv"
      style={{
        display: "block",
        height: "650px",
        overflow: "auto",
      }}
    >
      <InfiniteScroll
        dataLength={routes.length}
        next={() => fetchNextPage()}
        hasMore={hasNextPage || false}
        loader={
          <Spin
            spinning
            style={{
              height: "56px",
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          />
        }
        endMessage={<Divider plain>That&apos;s all, nothing more.</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={routes}
          grid={{
            gutter: [16, 16],
            column: 1,
          }}
          renderItem={(item) => (
            <List.Item style={{ height: "100%" }}>
              <Card
                hoverable
                // onClick={() => {
                //   return go({
                //     to: `${editUrl("routes", item?.id || "")}`,
                //   });
                // }}
                className={styles.card}
                onClick={() => setRouteCard(item)}
                style={{
                  border: route?.id === item.id ? "2px solid #1677ff" : "",
                }}
                styles={{
                  body: {
                    padding: "16px 16px 0 16px",
                  },
                  cover: {
                    position: "relative",
                  },
                  actions: {
                    marginTop: "auto",
                  },
                }}
                actions={[
                  <Flex
                    key="actions"
                    justify="space-between"
                    style={{
                      padding: "0 16px",
                    }}
                  >
                    <Flex gap="small" style={{ flexWrap: "wrap" }}>
                      {item.weekdays &&
                        item.weekdays.map((day: string) => {
                          const capitalizeFirstLetter = (str: string) =>
                            str.charAt(0).toUpperCase() +
                            str.slice(1).toLowerCase();
                          return (
                            <Tag
                              bordered={false}
                              color="processing"
                              style={{ height: "fit-content" }}
                              key={day}
                            >
                              {capitalizeFirstLetter(day)}
                            </Tag>
                          );
                        })}
                    </Flex>
                    {!item?.weekdays ? (
                      <Tag
                        bordered={false}
                        color="processing"
                        style={{ height: "fit-content" }}
                      >
                        <DateField
                          style={{ color: "#1677ff", fontSize: "12px" }}
                          value={dayjs(item.start_date)}
                          format="LL"
                        />
                      </Tag>
                    ) : null}
                    <Button
                      // icon={<EyeOutlined />}
                      style={{
                        display: "block",
                        marginLeft: "auto",
                      }}
                      onClick={() => {
                        return go({
                          to: `${editUrl("routes", item.id)}`,
                        });
                      }}
                    >
                      {t("actions.details")}
                    </Button>
                  </Flex>,
                ]}
              >
                <Card.Meta
                  title={
                    <Flex>
                      <Typography.Title
                        level={5}
                        style={{ display: "flex", gap: "5px" }}
                      >
                        {item.vehicle_type == "van" ? (
                          <AirportShuttleIcon sx={{ color: "#1677ff" }} />
                        ) : item.vehicle_type === "bus" ? (
                          <DirectionsBusIcon sx={{ color: "#1677ff" }} />
                        ) : item.vehicle_type === "car" ? (
                          <DirectionsCarIcon sx={{ color: "#1677ff" }} />
                        ) : null}
                        {item.name}
                      </Typography.Title>
                    </Flex>
                  }
                  description={
                    <Flex justify="space-between">
                      <Drivers drivers={drivers} value={item.drivers} />
                      <Typography style={{ display: "flex", gap: "5px" }}>
                        <AccessTimeIcon
                          sx={{ color: "#1677ff" }}
                          fontSize="small"
                        />
                        {item?.duration ? (
                          <NumberField
                            value={item?.duration || 0}
                            options={{
                              style: "unit",
                              unit: "minute",
                            }}
                          />
                        ) : (
                          "N/A"
                        )}
                      </Typography>
                      <Typography style={{ display: "flex", gap: "5px" }}>
                        <RouteIcon sx={{ color: "#1677ff" }} fontSize="small" />
                        <NumberField
                          value={item.distance}
                          options={{
                            style: "unit",
                            unit: "mile",
                          }}
                        />
                      </Typography>
                      {/* <PickUpStudents stops={item.stop_list} />
                    <DropOffStudents stops={item.stop_list} /> */}
                    </Flex>
                  }
                />
              </Card>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};
