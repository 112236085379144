const API_URL = "https://maps.googleapis.com/maps/api/geocode/json";
const API_KEY = import.meta.env.VITE_APP_MAP_ID ?? "";

export type LatLng = {
  lat: string | number;
  lng: string | number;
};

export type Place = {
  place_id: string;
  formatted_address: string;
  address_components: Array<{
    long_name: string;
    short_name: string;
    types: Array<string>;
  }>;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    viewport: {
      northeast: {
        lat: number;
        lng: number;
      };
      southwest: {
        lat: number;
        lng: number;
      };
    };
  };
};

export const getAddressWithLatLng = async ({ lat, lng }: LatLng) => {
  try {
    const query = `latlng=${lat},${lng}&key=${API_KEY}`;
    const response = await fetch(`${API_URL}?${query}`);
    const data = await response.json();
    if (!data || !data.results || data.results.length === 0) return null;

    return {
      address: data.results[0].formatted_address,
    };
  } catch (error) {
    return null;
  }
};

export const getLatLngWithAddress = async (address: string) => {
  try {
    const query = `address=${encodeURIComponent(address)}&key=${API_KEY}`;
    const response = await fetch(`${API_URL}?${query}`);
    const data = await response.json();
    if (!data || !data.results || data.results.length === 0) return null;

    const location = data.results[0].geometry.location;

    return {
      lat: location.lat,
      lng: location.lng,
    };
  } catch (error) {
    return null;
  }
};

export const convertLatLng = (latLng: { lat: string; lng: string }) => {
  const formatter = new Intl.NumberFormat("en", {
    maximumFractionDigits: 5,
  });
  const lat = Number(formatter.format(Number(latLng.lat)));
  const lng = Number(formatter.format(Number(latLng.lng)));

  return {
    lat,
    lng,
  };
};
