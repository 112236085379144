import { useEffect, useRef, useState } from "react";
import { useNavigation, useTranslate } from "@refinedev/core";
import {
  DeleteButton,
  ListButton,
  SaveButton,
  useForm,
  useSelect,
} from "@refinedev/antd";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputRef,
  Row,
  Select,
  TimePicker,
} from "antd";
import { ISchool } from "../../interfaces";
import { FormItemEditable, FormItemHorizontal } from "../../components";
import {
  BookOutlined,
  EditOutlined,
  LeftOutlined,
  RightCircleOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

export const SchoolEdit = () => {
  const titleInputRef = useRef<InputRef>(null);

  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const t = useTranslate();
  const { list } = useNavigation();
  const { formProps, queryResult, saveButtonProps } = useForm<ISchool>({
    redirect: false,
  });
  const school = queryResult?.data?.data;
  const organization = localStorage.getItem("organization");

  const { selectProps: locationsSelectProps } = useSelect({
    resource: "stops",
    defaultValue: school?.location,
    optionLabel: "address",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    queryOptions: {
      enabled: !!school?.location,
    },
  });

  const onChangeStartTime = (time: any, timeString: string | string[]) => {
    formProps.form?.setFieldValue("start_time", timeString);
  };
  const onChangeEndTime = (time: any, timeString: string | string[]) => {
    formProps.form?.setFieldValue("end_time", timeString);
  };

  useEffect(() => {
    if (!isFormDisabled) {
      titleInputRef.current?.focus();
    }
  }, [isFormDisabled]);

  return (
    <>
      <Flex>
        <ListButton icon={<LeftOutlined />}>{t("schools.schools")}</ListButton>
      </Flex>
      <Divider />

      <Row gutter={16}>
        <Col>
          <Form {...formProps} layout="horizontal" disabled={isFormDisabled}>
            <Flex align="center" gap={24}>
              <FormItemEditable
                formItemProps={{
                  name: "name",
                  style: {
                    width: "100%",
                    marginBottom: "0",
                  },
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
              >
                <Input
                  ref={titleInputRef}
                  size="large"
                  placeholder={t("schools.fields.name")}
                />
              </FormItemEditable>
            </Flex>
            <Card
              style={{
                marginTop: "16px",
              }}
              styles={{
                body: {
                  padding: 0,
                },
              }}
            >
              <FormItemHorizontal
                icon={<BookOutlined />}
                label={t("schools.fields.location")}
                name="location"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select {...locationsSelectProps} />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<RightCircleOutlined />}
                label={t("schools.fields.start_time")}
                // name="start_time"
              >
                <TimePicker
                  use12Hours
                  format="hh:mm A"
                  onChange={onChangeStartTime}
                  defaultValue={
                    school?.start_time
                      ? dayjs(school?.start_time, "hh:mm A")
                      : null
                  }
                />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<ShopOutlined />}
                label={t("schools.fields.end_time")}
                // name="end_time"
              >
                <TimePicker
                  use12Hours
                  format="hh:mm A"
                  onChange={onChangeEndTime}
                  defaultValue={
                    school?.end_time ? dayjs(school?.end_time, "hh:mm A") : null
                  }
                />
              </FormItemHorizontal>
              <Form.Item
                style={{ display: "none" }}
                name="start_time"
              ></Form.Item>
              <Form.Item
                style={{ display: "none" }}
                name="end_time"
              ></Form.Item>
            </Card>
            <Flex
              align="center"
              justify="space-between"
              style={{
                padding: "16px 16px 0px 16px",
              }}
            >
              {isFormDisabled ? (
                <>
                  <DeleteButton
                    type="text"
                    onSuccess={() => {
                      list("schools");
                    }}
                    style={{
                      marginLeft: "-16px",
                    }}
                  />
                  <Button
                    style={{
                      marginLeft: "auto",
                    }}
                    disabled={false}
                    icon={<EditOutlined />}
                    onClick={() => setIsFormDisabled(false)}
                  >
                    {t("actions.edit")}
                  </Button>
                </>
              ) : (
                <>
                  <Button onClick={() => setIsFormDisabled(true)}>
                    {t("actions.cancel")}
                  </Button>
                  <SaveButton
                    {...saveButtonProps}
                    disabled={isFormDisabled}
                    style={{
                      marginLeft: "auto",
                    }}
                    htmlType="submit"
                    type="primary"
                    icon={null}
                  >
                    Save
                  </SaveButton>
                </>
              )}
            </Flex>
          </Form>
        </Col>
        {/* <Col
          span={14}
          style={{
            marginTop: "60px",
          }}
        >
          <CardWithContent
            bodyStyles={{
              height: "400px",
              overflow: "hidden",
            }}
            icon={
              <ShoppingOutlined
                style={{
                  fontSize: 14,
                  color: token.colorPrimary,
                }}
              />
            }
            title={t("dashboard.recentOrders.title")}
          ></CardWithContent>
        </Col> */}
      </Row>
    </>
  );
};
