import type { IResourceItem } from "@refinedev/core";

import {
  UserOutlined,
  UsergroupAddOutlined,
  CarOutlined,
  StopOutlined,
  EnvironmentOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import RouteIcon from "@mui/icons-material/Route";

export const resources: IResourceItem[] = [
  {
    name: "dashboard",
    list: "/",
    meta: {
      label: "Dashboard",
      icon: <DashboardCustomizeOutlinedIcon />,
    },
  },
  {
    name: "routes",
    list: "/routes",
    edit: "/routes/:id/edit",
    create: "/routes/new",
    meta: {
      label: "Routes",
      icon: <RouteIcon />,
    },
  },
  {
    name: "trips",
    list: "/trips",
    edit: "/trips/:id/edit",
    create: "/trips/new",
    meta: {
      label: "Trips",
      icon: <EnvironmentOutlined />,
    },
  },
  {
    name: "reports",
    list: "/reports",
    edit: "/reports/:id/edit",
    create: "/reports/new",
    meta: {
      label: "Reports",
      icon: <BarChartOutlined />,
    },
  },
  {
    name: "stops",
    list: "/stops",
    create: "/stops/new",
    edit: "/stops/:id/edit",
    meta: {
      label: "Stops",
      icon: <StopOutlined />,
    },
  },
  {
    name: "schools",
    list: "/schools",
    create: "/schools/new",
    edit: "/schools/:id/edit",
    show: "/schools/:id",
    meta: {
      icon: <SchoolOutlinedIcon />,
    },
  },
  {
    name: "students",
    list: "/students",
    create: "/students/new",
    edit: "/students/:id/edit",
    show: "/students/:id",
    meta: {
      icon: <UsergroupAddOutlined />,
    },
  },
  {
    name: "drivers",
    list: "/drivers",
    create: "/drivers/new",
    edit: "/drivers/:id/edit",
    show: "/drivers/:id",
    meta: {
      icon: <UserOutlined />,
    },
  },
  {
    name: "vehicles",
    list: "/vehicles",
    create: "/vehicles/new",
    edit: "/vehicles/:id/edit",
    show: "/vehicles/:id",
    meta: {
      icon: <CarOutlined />,
    },
  },
  {
    name: "logout",
    meta: {
      hide: true,
    },
  },
];
