import { Tag, Typography, theme } from "antd";
import { CheckCircleOutlined, StopOutlined, SyncOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { useConfigProvider } from "../../../context";

type Props = {
  value: "completed" | "in_progress" | "cancelled" | "not_started";
};

export const TripStatus = ({ value }: Props) => {
  const t = useTranslate();
  const { token } = theme.useToken();
  const { mode } = useConfigProvider();
  const isDark = mode === "dark";

  // Determine color, icon, and text color based on the status value
  let tagColor = "default";
  let textColor = token.colorTextTertiary;
  let icon = <StopOutlined />;

  if (value === "completed") {
    tagColor = "green";
    textColor = isDark ? token.green7 : "#3C8618";
    icon = <CheckCircleOutlined />;
  } else if (value === "in_progress") {
    tagColor = "blue";
    textColor = isDark ? token.blue7 : "#1677FF";
    icon = <SyncOutlined spin />;
  } else if (value === "cancelled") {
    tagColor = "red";
    textColor = isDark ? token.red7 : "#CF1322";
    icon = <StopOutlined />;
  } else if (value === "not_started") {
    tagColor = "orange";
    textColor = isDark ? token.orange7 : "#FA8C16";
    icon = <ClockCircleOutlined />;
  }

  return (
    <Tag
      color={tagColor}
      style={{ marginInlineEnd: 0 }}
      icon={icon}
    >
      <Typography.Text
        style={{ color: textColor }}
      >
        {t(`trips.fields.status.${value}`)}
      </Typography.Text>
    </Tag>
  );
};
