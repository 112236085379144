export const calculateTotalDistance = (directions: any): string | number=> {
  if (!directions || !directions.routes || directions.routes.length === 0) {
    return 0;
  }

  const route = directions.routes[0];
  let totalDistance = 0;

  if (route.legs && route.legs.length > 0) {
    route.legs.forEach((leg: { distance: { value: number; }; }) => {
      if (leg.distance && leg.distance.value) {
        totalDistance += leg.distance.value;
      }
    });
  }

  const distanceInMiles = (totalDistance * 0.000621371).toFixed(1);
  return distanceInMiles;
};
