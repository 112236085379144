import { useTranslate, useExport, useMany, useList } from "@refinedev/core";
import {
  List,
  useTable,
  ExportButton,
  EditButton,
  DateField,
} from "@refinedev/antd";
import { Table, Typography } from "antd";
import { IReport, IRoute, ISchool, IVehicle } from "../../interfaces";
import { EyeOutlined } from "@ant-design/icons";
import { PaginationTotal } from "../../components";
import { PropsWithChildren } from "react";
import { SeverityLevel } from "../../components/reports";

export const ReportsList = ({ children }: PropsWithChildren) => {
  const t = useTranslate();
  const organization = localStorage.getItem("organization");

  const { tableProps } = useTable<IReport>({
    resource: "reports",
    filters: {
      initial: [
        {
          field: "organization_id",
          operator: "eq",
          value: organization,
        },
      ],
    },
    pagination: {
      mode: "off",
    },
  });
  const reports = tableProps.dataSource ?? [];
  const vehiclesIds = reports.map((item) => item.vehicle);
  const { data: vehiclesData } = useMany<IVehicle>({
    resource: "vehicles",
    ids: vehiclesIds,
    queryOptions: {
      enabled: vehiclesIds.length > 0,
    },
  });

  const { data } = useList<IVehicle>({
    resource: "vehicles",
    pagination: {
      mode: "off",
    },
  });
  const vehicles = data?.data;

  const getVehicle = (vehicleId: number | string): string | null => {
    const vehicle = vehicles?.find((vehicle) => vehicle.id === vehicleId);
    return vehicle ? vehicle.vehicle_model : null;
  };

  const { isLoading, triggerExport } = useExport<IReport>({
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    mapData: (item) => {
      return {
        id: item.id,
        vehicle: getVehicle(item.vehicle),
        address: item.address,
        damage_area: item.damage_area,
        description: item.description,
        severity_level: item.severity_level,
        date: item.date,
      };
    },
  });

  return (
    <List
      breadcrumb={false}
      headerButtons={(props) => (
        <ExportButton
          size="large"
          onClick={triggerExport}
          loading={isLoading}
        />
      )}
    >
      <Table
        {...tableProps}
        rowKey="id"
        scroll={{ x: true }}
        pagination={{
          ...tableProps.pagination,
          showTotal: (total) => (
            <PaginationTotal total={total} entityName="reports" />
          ),
        }}
      >
        <Table.Column<IRoute>
          title={t("reports.fields.vehicle")}
          dataIndex="vehicle"
          key="vehicle"
          render={(value) => {
            const vehicle = vehiclesData?.data.find(
              (vehicle) => vehicle?.id === value
            );

            return (
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {vehicle?.vehicle_model || "-"}
              </Typography.Text>
            );
          }}
        />
        <Table.Column
          title={t("reports.fields.address")}
          dataIndex="address"
          key="address"
        />
        <Table.Column
          title={t("reports.fields.damage_area")}
          dataIndex="damage_area"
          key="damage_area"
        />
        <Table.Column
          dataIndex="severity_level"
          title={t("reports.fields.severity_level")}
          render={(value) => <SeverityLevel value={value} />}
        />
        <Table.Column
          dataIndex={"date"}
          title={t("reports.fields.date")}
          render={(value) => <DateField value={value} format="LLL" />}
        />
        {/* <Table.Column
          title={t("table.actions")}
          key="actions"
          fixed="right"
          align="center"
          render={(_, record: ISchool) => {
            return (
              <EditButton
                icon={<EyeOutlined />}
                hideText
                recordItemId={record.id}
              />
            );
          }}
        /> */}
      </Table>
      {children}
    </List>
  );
};
