import {
  useTranslate,
  getDefaultFilter,
  useNavigation,
  useGo,
  useMany,
  useExport,
  useList,
} from "@refinedev/core";
import {
  CreateButton,
  EditButton,
  ExportButton,
  FilterDropdown,
  List,
  useTable,
} from "@refinedev/antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Table, Typography, theme, Input } from "antd";
import { IDriver, ILocation, IStop, IVehicle } from "../../interfaces";
import { PaginationTotal } from "../../components";
import { useLocation } from "react-router-dom";
import { PropsWithChildren } from "react";

export const DriverList = ({ children }: PropsWithChildren) => {
  const go = useGo();
  const { pathname } = useLocation();
  const { createUrl } = useNavigation();
  const t = useTranslate();
  const { token } = theme.useToken();
  const organization = localStorage.getItem("organization");

  const { tableProps, filters } = useTable<IDriver>({
    filters: {
      initial: [
        {
          field: "organization_id",
          operator: "eq",
          value: organization,
        },
        {
          field: "name",
          operator: "contains",
          value: "",
        },
      ],
    },
  });
  const drivers = tableProps.dataSource ?? [];

  const locationsIds = drivers
  .map((item) => item.home_location)
  .filter((location) => location !== null);

  const { data: locationsData } = useMany<IStop>({
    resource: "stops",
    ids: locationsIds,
    queryOptions: {
      enabled: locationsIds.length > 0,
    },
  });

  const { data } = useList<IStop>({
    resource: "stops",
    pagination: {
      mode: "off",
    },
  });
  const locations = data?.data;

  const getLocation = (locationId: number): string | null => {
    const location = locations?.find((location) => location.id === locationId);
    return location ? location.address : null;
  };

  const { isLoading, triggerExport } = useExport<IDriver>({
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    mapData: (item) => {
      return {
        id: item.id,
        name: item.name,
        location: getLocation(item.home_location),
        license: item.license,
      };
    },
  });

  return (
    <>
      <List
        breadcrumb={false}
        headerButtons={(props) => (
          <>
            <CreateButton
              {...props.createButtonProps}
              key="create"
              size="large"
              onClick={() => {
                return go({
                  to: `${createUrl("drivers")}`,
                  query: {
                    to: pathname,
                  },
                  options: {
                    keepQuery: true,
                  },
                  type: "replace",
                });
              }}
            >
              {t("drivers.actions.add")}
            </CreateButton>
            <ExportButton
              size="large"
              onClick={triggerExport}
              loading={isLoading}
            />
          </>
        )}
      >
        <Table
          {...tableProps}
          rowKey="id"
          scroll={{ x: true }}
          pagination={{
            ...tableProps.pagination,
            showTotal: (total) => (
              <PaginationTotal total={total} entityName="drivers" />
            ),
          }}
        >
          <Table.Column<IDriver>
            key="name"
            dataIndex="name"
            title={t("drivers.fields.name.label")}
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{
                  color: filtered ? token.colorPrimary : undefined,
                }}
              />
            )}
            defaultFilteredValue={getDefaultFilter("name", filters, "contains")}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input style={{ width: "100%" }} placeholder={"Search driver"} />
              </FilterDropdown>
            )}
          />
          <Table.Column<IStop>
            title={t("drivers.fields.address.label")}
            dataIndex="home_location"
            key="home_location"
            render={(value) => {
              const location = locationsData?.data.find(
                (location) => location?.id === value
              );

              return (
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {location?.address || "-"}
                </Typography.Text>
              );
            }}
          />
          <Table.Column<IDriver>
            dataIndex={"license"}
            key="license"
            title={t("drivers.fields.license.label")}
          />
          <Table.Column
            title={t("table.actions")}
            key="actions"
            fixed="right"
            align="center"
            render={(_, record: IDriver) => {
              return (
                <EditButton
                  icon={<EyeOutlined />}
                  hideText
                  recordItemId={record.id}
                />
              );
            }}
          />
        </Table>
      </List>
      {children}
    </>
  );
};
