import React, { useEffect, useState, useCallback } from "react";
import { BaseOption, useList, useTranslate } from "@refinedev/core";
import { EditButton, useSelect } from "@refinedev/antd";
import { IRoute, IStop, IStudent } from "../../../interfaces";
import {
  Button,
  Card,
  Form,
  List,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { LabeledValue } from "antd/es/select";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { v4 as uuidv4 } from "uuid";

const ItemType = "STOP_ITEM";

interface DragItemProps {
  item: IStop;
  index: number;
  isDragging: boolean;
  moveItem: (fromIndex: number, toIndex: number) => void;
  removeStop: (id: number | string) => void;
  onChangePickupStudents: (value: any, id: number) => void;
  onChangeDropoffStudents: (value: any, id: number) => void;
  remainingCapacity: number;
  stopsData: IStop[] | undefined,
}

const DragItem: React.FC<DragItemProps> = ({
  item,
  index,
  isDragging,
  moveItem,
  removeStop,
  onChangePickupStudents,
  onChangeDropoffStudents,
  remainingCapacity,
  stopsData,
}) => {
  const [, drag, preview] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const getStopById = (id: string | number) => {
    return stopsData?.find((stop) => stop.id === id);
  };

  const stopDetails = getStopById(item.id);

  const organization = localStorage.getItem("organization");

  const { selectProps: studentsSelectProps } = useSelect<IStudent>({
    resource: "students",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  const [, drop] = useDrop({
    accept: ItemType,
    drop: (draggedItem: { index: number }) => {
      moveItem(draggedItem.index, index);
    },
  });

  return (
    <div ref={preview} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div ref={drop}>
        <Card
          hoverable
          ref={drag}
          size="small"
          bordered={false}
          style={{ padding: 5 }}
          title={
            <Typography.Title style={{ marginTop: 10 }} level={5}>
              {stopDetails?.address}
            </Typography.Title>
          }
          extra={
            <div
              key="actions"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "4px",
                padding: "0 10px",
              }}
            >
              <EditButton
                style={{
                  width: "100%",
                  padding: 5,
                }}
                hideText
                size="small"
                icon={<EditOutlined />}
                recordItemId={item.id}
                resource="stops"
              />
              <Button
                size="small"
                style={{ width: "100%", padding: 5 }}
                danger
                icon={<DeleteOutlined />}
                onClick={() => removeStop(item.instanceId)}
              />
            </div>
          }
          className="draggable-item"
        >
          <Space style={{ width: "100%", gap: "2px" }} direction="vertical">
            <Typography.Text
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
            >
              Pick-up Students
            </Typography.Text>
            <Select
              mode="multiple"
              size="small"
              style={{ width: "100%", marginBottom: "5px" }}
              placeholder="Pick-up Students"
              // maxCount={remainingCapacity}
              defaultValue={item.pick_up_students}
              onChange={(value) => {
                onChangePickupStudents(value, item.id);
              }}
              {...studentsSelectProps}
            />
            <Typography.Text
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
            >
              Drop-off Students
            </Typography.Text>
            <Select
              mode="multiple"
              size="small"
              style={{ width: "100%" }}
              placeholder="Drop-off Students"
              defaultValue={item.drop_off_students}
              onChange={(value) => {
                onChangeDropoffStudents(value, item.id);
              }}
              {...studentsSelectProps}
            />
          </Space>
        </Card>
      </div>
    </div>
  );
};

interface DragListProps {
  items: IStop[];
  moveItem: (fromIndex: number, toIndex: number) => void;
  removeStop: (id: number | string) => void;
  onChangePickupStudents: (value: any, id: number) => void;
  onChangeDropoffStudents: (value: any, id: number) => void;
  remainingCapacity: number;
  stopsData: IStop[] | undefined,
}

const DragList: React.FC<DragListProps> = ({
  items,
  moveItem,
  removeStop,
  onChangePickupStudents,
  onChangeDropoffStudents,
  remainingCapacity,
  stopsData,
}) => {
  return (
    <List
      grid={{
        gutter: [16, 16],
        column: 1,
      }}
    >
      {items.map((item, index) => (
        <List.Item key={item.instanceId}>
          <DragItem
            item={item}
            index={index}
            isDragging={false}
            moveItem={moveItem}
            removeStop={removeStop}
            onChangePickupStudents={onChangePickupStudents}
            onChangeDropoffStudents={onChangeDropoffStudents}
            remainingCapacity={remainingCapacity}
            stopsData={stopsData}
          />
        </List.Item>
      ))}
    </List>
  );
};

type Props = {
  stops: IStop[];
  route: IRoute | undefined;
  totalCapacity: number;
  onUpdateStops: (updatedStops: IStop[]) => void;
};

export const StopsListCard: React.FC<Props> = (props) => {
  const [data, setData] = useState<any[]>([]);
  const t = useTranslate();
  const [messageApi, contextHolder] = message.useMessage();
  const organization = localStorage.getItem("organization");
  const [remainingCapacity, setRemainingCapacity] = useState<number>(
    props.totalCapacity
  );

  const moveItem = useCallback(
    (fromIndex: number, toIndex: number) => {
      const updatedData = [...data];
      const [movedItem] = updatedData.splice(fromIndex, 1);
      updatedData.splice(toIndex, 0, movedItem);
      setData(updatedData);
      props.onUpdateStops(updatedData);
    },
    [data, props.onUpdateStops]
  );

  const calculateInitialCapacity = (stops: IStop[]) => {
    let totalPickUpStudents = 0;
    let totalDropOffStudents = 0;

    stops.forEach((stop) => {
      totalPickUpStudents += stop.pick_up_students
        ? stop.pick_up_students.length
        : 0;
      totalDropOffStudents += stop.drop_off_students
        ? stop.drop_off_students.length
        : 0;
    });

    // Calculate the remaining capacity by subtracting pick-up students and adding drop-off students
    const totalStudents = totalPickUpStudents - totalDropOffStudents;
    return props.totalCapacity - totalStudents;
  };

  const { selectProps: stopsSelectProps } = useSelect<IStop>({
    resource: "stops",
    optionLabel: "address",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  const { data: stopsData } = useList<IStop>({
    resource: "stops",
    pagination: {
      mode: "off",
    },
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  const updateCapacity = (
    addedStudents: BaseOption[],
    removedStudents: BaseOption[],
    action: "pickup" | "dropoff"
  ) => {
    const addedCount = addedStudents.length;
    const removedCount = removedStudents.length;

    // For pickup, increase the count. For dropoff, decrease the count.
    if (action === "pickup") {
      setRemainingCapacity((prev) => prev - addedCount + removedCount);
    } else if (action === "dropoff") {
      setRemainingCapacity((prev) => prev + addedCount - removedCount);
    }
  };

  const onChangePickupStudents = (value: any, id: number) => {
    const stop = data.find((s) => s.id === id);
    if (stop) {
      const updatedStops = data.map((s) =>
        s.id === id ? { ...s, pick_up_students: value } : s
      );
      setData(updatedStops);
      setRemainingCapacity(remainingCapacity + 1);
      props.onUpdateStops(updatedStops);
    }
  };

  const onChangeDropoffStudents = (value: any, id: number) => {
    const stop = data.find((s) => s.id === id);
    if (stop) {
      const previousDropoffStudents = stop.drop_off_students || [];
      updateCapacity(value, previousDropoffStudents, "dropoff");

      const updatedStops = data.map((s) =>
        s.id === id ? { ...s, drop_off_students: value } : s
      );
      setData(updatedStops);
      props.onUpdateStops(updatedStops);
    }
  };

  const removeStop = (id: string | number) => {
    const updatedStops = data.filter((item) => item.instanceId !== id);
    setData(updatedStops);
    props.onUpdateStops(updatedStops);
  };

  const addStop = (value: string | number | LabeledValue) => {
    const selectedStop = stopsData?.data.find((option) => option.id === value);

    if (selectedStop) {
      const stopWithInstanceId = {
        id: selectedStop.id,
        address: selectedStop.address,
        instanceId: uuidv4(),
      };
      const updatedStops = [...data, stopWithInstanceId];
      setData(updatedStops);
      props.onUpdateStops(updatedStops);
    }
  
  };
  

  useEffect(() => {
    setData(props.stops);
    const initialCapacity = calculateInitialCapacity(props.stops);
    setRemainingCapacity(initialCapacity);
  }, [props.stops, props.totalCapacity]);

  useEffect(() => {
    if (remainingCapacity <= 0) {
      messageApi.open({
        type: "warning",
        content: `You are exceeding the vehicle’s passenger capacity. It can only accommodate ${props.totalCapacity} passengers.`,
      });
    }
  }, [remainingCapacity]);

  return (
    <>
    {contextHolder}
      <DndProvider backend={HTML5Backend}>
        <div
          id="scrollableDiv"
          style={{
            height: "650px",
            overflow: "auto",
          }}
        >
          {/* <Typography.Title level={5}>
            Remaining Capacity: {remainingCapacity}
          </Typography.Title> */}
          {data.length ? (
            <DragList
              items={data}
              moveItem={moveItem}
              removeStop={removeStop}
              onChangePickupStudents={onChangePickupStudents}
              onChangeDropoffStudents={onChangeDropoffStudents}
              remainingCapacity={remainingCapacity}
              stopsData={stopsData?.data}
            />
          ) : (
            <Card style={{ marginBottom: "10px" }} size="small">
              <Card.Meta
                title={t("stops.stops")}
                description={t("stops.empty")}
              />
            </Card>
          )}
          <Form.Item>
            <Select
              placeholder={t("actions.add")}
              allowClear
              onSelect={addStop}
              {...stopsSelectProps}
            />
          </Form.Item>
        </div>
      </DndProvider>
    </>
  );
};
