import { useState, useEffect, memo, useRef, PropsWithChildren } from "react";
import { Root, createRoot } from "react-dom/client";

type AdvancedPolylineProps = {
  map?: google.maps.Map;
  path: google.maps.LatLngLiteral[] | google.maps.MVCArray<google.maps.LatLngLiteral>;
  options?: google.maps.PolylineOptions;
};

const Polyline: React.FC<PropsWithChildren<AdvancedPolylineProps>> = ({
  map,
  path,
  options = {},
  children
}) => {
  const rootRef = useRef<Root | null>(null);
  const [polyline, setPolyline] = useState<google.maps.Polyline | undefined>();

  useEffect(() => {
    if (!map) return;

    if (!polyline) {
      const polylineOptions: google.maps.PolylineOptions = {
        ...options,
        path: path as google.maps.LatLngLiteral[],
        strokeColor: '#1677ff',
        strokeWeight: 1,
        map
      };
      const newPolyline = new google.maps.Polyline(polylineOptions);
      setPolyline(newPolyline);
    }
  }, [map, path, options, polyline]);

  useEffect(() => {
    if (polyline) {
      polyline.setOptions(options);
    }
  }, [polyline, options]);

  useEffect(() => {
    if (polyline) {
      if (rootRef && rootRef.current) {
        rootRef.current.render(children);
      }
    }
  }, [polyline, children]);

  return null;
};

export default memo(Polyline);
