// src/components/MapComponent.tsx
import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";
import { processStops } from "../../utils/processStops";
import { IStop } from "../../interfaces";
import { calculateTotalDistance } from "../../utils/calculateTotalDistance";
import { calculateTotalDuration } from "../../utils/calculateTotalDuration";

interface ProcessedStops {
  origin: google.maps.LatLngLiteral | string;
  destination: google.maps.LatLngLiteral | string;
  waypoints: google.maps.DirectionsWaypoint[];
}

interface MapComponentProps {
  stops: IStop[];
}

const MapComponent: React.FC<MapComponentProps> = ({ stops }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_APP_MAP_ID ?? "",
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(null);
  const [processedStops, setProcessedStops] = useState<ProcessedStops | null>(
    null
  );
  const [coordinates, setCoordinates] = useState<
    { id: number; location: google.maps.LatLng }[]
  >([]);

  useEffect(() => {
    if (!isLoaded || stops.length === 0) return;

    const geocoder = new google.maps.Geocoder();
    const geocodePromises = stops.map(
      (stop) =>
        new Promise<{ id: number; location: google.maps.LatLng }>(
          (resolve, reject) => {
            geocoder.geocode({ address: stop.address }, (results, status) => {
              if (
                status === google.maps.GeocoderStatus.OK &&
                results &&
                results[0]
              ) {
                resolve({
                  id: stop.id,
                  location: results[0].geometry.location,
                });
              } else {
                reject(
                  `Geocode failed for address: ${stop.address} with status: ${status}`
                );
              }
            });
          }
        )
    );

    Promise.all(geocodePromises)
      .then((results) => {
        setCoordinates(results);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [isLoaded, stops]);

  useEffect(() => {
    const processed = processStops(stops);
    setProcessedStops(processed);
  }, [stops]);

  useEffect(() => {
    if (!isLoaded || stops.length < 2) {
        setDirections(null);
        localStorage.removeItem('total');
        return;
    };

    if (processedStops) {
      if (stops.length < 2) {
        return;
      }

      const directionsService = new google.maps.DirectionsService();

      directionsService.route(
        {
          origin: processedStops.origin,
          destination: processedStops.destination,
          waypoints: processedStops.waypoints,
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
            const totalDistance = calculateTotalDistance(result);
            const totalDuration = calculateTotalDuration(result);
            localStorage.setItem('distance', totalDistance.toString());
            localStorage.setItem('duration', totalDuration.toString());
          } else {
            console.error(`Directions request failed due to ${status}`);
          }
        }
      );
    }
  }, [isLoaded, processedStops]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const mapCenter = coordinates.length > 0 ? coordinates[0].location : { lat: 42.9552965, lng: -85.5780721 };

  return (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "100%" }}
      center={mapCenter}
      zoom={6} // Initial zoom
      onLoad={(map) => setMap(map as google.maps.Map)}
    >
      {stops.length === 1 && (
        <Marker position={coordinates[0]?.location} label={{
            text: "A",
            color: "white",
          }} />
      )}
      {directions && stops.length > 1 && (
        <DirectionsRenderer options={{ directions: directions }} />
      )}
    </GoogleMap>
  );
};

export default MapComponent;
