import {
  useTranslate,
  useExport,
  useGo,
  useNavigation,
  useMany,
  useList,
  getDefaultFilter,
} from "@refinedev/core";
import {
  List,
  useTable,
  FilterDropdown,
  ExportButton,
  CreateButton,
  EditButton,
} from "@refinedev/antd";
import { Table, Typography, theme, Input } from "antd";
import { ISchool, IStop, IStudent } from "../../interfaces";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { PaginationTotal } from "../../components";
import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";

export const StudentList = ({ children }: PropsWithChildren) => {
  const go = useGo();
  const { pathname } = useLocation();
  const { createUrl } = useNavigation();
  const t = useTranslate();
  const { token } = theme.useToken();
  const organization = localStorage.getItem("organization");

  const { tableProps, sorters, filters } = useTable<IStudent>({
    resource: "students",
    filters: {
      initial: [
        {
          field: "organization_id",
          operator: "eq",
          value: organization,
        },
        {
          field: "name",
          operator: "contains",
          value: "",
        },
      ],
    },
  });
  const students = tableProps.dataSource ?? [];

  const locationsIds = students
  .map((item) => item.home_location)
  .filter((location) => location !== null);

  const { data: locationsData } = useMany<IStop>({
    resource: "stops",
    ids: locationsIds,
    queryOptions: {
      enabled: locationsIds.length > 0,
    },
  });

  const { data: listStops } = useList<IStop>({
    resource: "stops",
    pagination: {
      mode: "off",
    },
  });
  const locations = listStops?.data;

  const getLocation = (locationId: number): string | null => {
    const location = locations?.find((location) => location.id === locationId);
    return location ? location.address : null;
  };

  const schoolsIds = students.map((item) => item.school);
  const { data: schoolsData } = useMany<ISchool>({
    resource: "schools",
    ids: schoolsIds,
    queryOptions: {
      enabled: schoolsIds.length > 0,
    },
  });

  const { data } = useList<ISchool>({
    resource: "schools",
    pagination: {
      mode: "off",
    },
  });
  const schools = data?.data;

  const getSchool = (schoolId: string): string | null => {
    const school = schools?.find((school) => school.id === schoolId);
    return school ? school.name : null;
  };

  const { isLoading, triggerExport } = useExport<IStudent>({
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    mapData: (item) => {
      return {
        id: item.id,
        name: item.name,
        school: getSchool(item.school),
        grade: item.grade,
        location: getLocation(item.home_location),
      };
    },
  });

  return (
    <List
      breadcrumb={false}
      headerButtons={(props) => (
        <>
          <CreateButton
            {...props.createButtonProps}
            key="create"
            size="large"
            onClick={() => {
              return go({
                to: `${createUrl("students")}`,
                query: {
                  to: pathname,
                },
                options: {
                  keepQuery: true,
                },
                type: "replace",
              });
            }}
          >
            {t("students.actions.add")}
          </CreateButton>
          <ExportButton
            size="large"
            onClick={triggerExport}
            loading={isLoading}
          />
        </>
      )}
    >
      <Table
        {...tableProps}
        rowKey="id"
        scroll={{ x: true }}
        pagination={{
          ...tableProps.pagination,
          showTotal: (total) => (
            <PaginationTotal total={total} entityName="students" />
          ),
        }}
      >
        <Table.Column
          key="name"
          dataIndex={"name"}
          title={t("students.fields.name.label")}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? token.colorPrimary : undefined,
              }}
            />
          )}
          defaultFilteredValue={getDefaultFilter("name", filters, "contains")}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Input style={{ width: "100%" }} placeholder={"Search student"} />
            </FilterDropdown>
          )}
        />
        <Table.Column<IStop>
          title={t("drivers.fields.address.label")}
          dataIndex="home_location"
          key="home_location"
          render={(value) => {
            const location = locationsData?.data.find(
              (location) => location?.id === value
            );

            return (
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {location?.address || "-"}
              </Typography.Text>
            );
          }}
        />
        <Table.Column<ISchool>
          title={t("students.fields.school")}
          dataIndex="school"
          key="school"
          render={(value) => {
            const school = schoolsData?.data.find(
              (school) => school?.id === value
            );

            return (
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {school?.name || "-"}
              </Typography.Text>
            );
          }}
        />
        <Table.Column
          dataIndex={"grade"}
          title={t("students.fields.grade")}
          sorter
        />
        <Table.Column
          title={t("table.actions")}
          key="actions"
          fixed="right"
          align="center"
          render={(_, record: IStudent) => {
            return (
              <EditButton
                icon={<EyeOutlined />}
                hideText
                recordItemId={record.id}
              />
            );
          }}
        />
      </Table>
      {children}
    </List>
  );
};
