import { useState } from "react";

import { useTranslate } from "@refinedev/core";

import {
  CloseOutlined,
  SafetyCertificateOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Drawer,
  Flex,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";

import styles from "./index.module.css";
import { SaveButton, useForm, useSelect } from "@refinedev/antd";
import { IUser } from "../../interfaces";
import InputMask from "react-input-mask";

type Props = {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  userId: string | number;
  userName: string;
};

type FormKeys = "email" | "jobTitle" | "phone" | "timezone";

export const AccountSettings = ({
  opened,
  setOpened,
  userId,
  userName,
}: Props) => {
  const [activeForm, setActiveForm] = useState<FormKeys>();
  const t = useTranslate();
  const organization = localStorage.getItem("organization");

  const closeModal = () => {
    setOpened(false);
  };

  const {
    formProps: profileFormProps,
    saveButtonProps: profileSaveButtonProps,
  } = useForm<IUser>({
    action: "edit",
    resource: "profiles",
    id: userId,
    redirect: false,
  });

  const {
    formProps: organizationFormProps,
    saveButtonProps: organizationSaveButtonProps,
  } = useForm<IUser>({
    action: "edit",
    resource: "organizations",
    id: Number(organization),
    redirect: false,
  });

  const { selectProps: locationsSelectProps } = useSelect({
    resource: "stops",
    optionLabel: "address",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  // if (isError) {
  //   closeModal();
  //   return null;
  // }

  // if (isLoading) {
  //   return (
  //     <Drawer
  //       open={opened}
  //       width={756}
  //       bodyStyle={{
  //         background: "#f5f5f5",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Spin />
  //     </Drawer>
  //   );
  // }

  return (
    <Drawer
      onClose={closeModal}
      open={opened}
      width={756}
      styles={{
        body: { background: "#f5f5f5", padding: 0 },
        header: { display: "none" },
      }}
    >
      <div className={styles.header}>
        <Typography.Text strong>Account Settings</Typography.Text>
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={() => closeModal()}
        />
      </div>
      <div className={styles.container}>
        <Card
          title={
            <Space size={15}>
              <UserOutlined />
              <Typography.Text>User profile</Typography.Text>
            </Space>
          }
        >
          <Form {...profileFormProps} layout="horizontal" labelCol={{ span: 5 }} labelAlign="left">
            <Form.Item name={"fname"} label={t("profiles.fields.firstName.label")}>
              <Input placeholder={t("profiles.fields.firstName.placeholder")} />
            </Form.Item>
            <Form.Item name={"lname"} label={t("profiles.fields.lastName.label")}>
              <Input placeholder={t("profiles.fields.lastName.placeholder")} />
            </Form.Item>
            <Form.Item name="user_phone" label={t("profiles.fields.phone.label")}>
              <InputMask mask="999-999-9999">
                {/* 
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore */}
                {(props: InputProps) => (
                  <Input
                    {...props}
                    placeholder={t("profiles.fields.phone.placeholder")}
                  />
                )}
              </InputMask>
            </Form.Item>
            <Flex
              align="center"
              justify="space-between"
              style={{
                padding: "16px 16px 0px 16px",
              }}
            >
              <SaveButton
                {...profileSaveButtonProps}
                style={{
                  marginLeft: "auto",
                }}
                htmlType="submit"
                type="primary"
                icon={null}
              >
                Save
              </SaveButton>
            </Flex>
          </Form>
        </Card>
        <Card
          title={
            <Space size={15}>
              <SafetyCertificateOutlined />
              <Typography.Text>Organization</Typography.Text>
            </Space>
          }
        >
          <Form {...organizationFormProps} layout="horizontal" labelCol={{ span: 5 }} labelAlign="left">
            <Form.Item
              name={"organization_name"}
              label={t("organizations.fields.organization_name.label")}
            >
              <Input
                placeholder={t(
                  "organizations.fields.organization_name.placeholder"
                )}
              />
            </Form.Item>
            <Form.Item name={"contact_name"} label={t("organizations.fields.contact_name.label")}>
              <Input
                placeholder={t("organizations.fields.contact_name.placeholder")}
              />
            </Form.Item>
            <Form.Item name="contact_number" label={t("organizations.fields.contact_number.label")}>
              <InputMask mask="999-999-9999">
                {/* 
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore */}
                {(props: InputProps) => (
                  <Input
                    {...props}
                    placeholder={t(
                      "organizations.fields.contact_number.placeholder"
                    )}
                  />
                )}
              </InputMask>
            </Form.Item>
            <Form.Item name={"contact_email"} label={t("organizations.fields.contact_email.label")}>
              <Input
                type="email"
                placeholder={t(
                  "organizations.fields.contact_email.placeholder"
                )}
              />
            </Form.Item>
            <Form.Item name={"location"} label={t("organizations.fields.location.label")}>
              <Select
                {...locationsSelectProps}
                placeholder={t("organizations.fields.location.placeholder")}
              />
            </Form.Item>
            <Flex
              align="center"
              justify="space-between"
              style={{
                padding: "16px 16px 0px 16px",
              }}
            >
              <SaveButton
                {...organizationSaveButtonProps}
                style={{
                  marginLeft: "auto",
                }}
                htmlType="submit"
                type="primary"
                icon={null}
              >
                Save
              </SaveButton>
            </Flex>
          </Form>
        </Card>
      </div>
    </Drawer>
  );
};
