import { useState } from 'react';
import { IStop } from '../../../interfaces';
import { Flex } from 'antd';
import { MapComponent } from '../../map';

type Props = {
  stops: IStop[];
};

export const RouteStops: React.FC<Props> = ({ stops }) => {

  return (
    <Flex style={{ height: '100%', width: '100%', position: 'relative', borderRadius: '8px', overflow: 'hidden', flexDirection: 'column' }}>
      <MapComponent stops={stops} />
    </Flex>
  );
};
