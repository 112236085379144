import {
  useExport,
  useGo,
  useList,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { CreateButton, ExportButton, List } from "@refinedev/antd";
import { useLocation } from "react-router-dom";
import { RouteStops, RoutesListCard } from "../../components";
import { Row, Col, Divider, Segmented, Checkbox, CheckboxProps, Radio } from "antd";
import { IDriver, IRoute, IStop, IVehicle } from "../../interfaces";
import { useState } from "react";
import {
  CheckCircleOutlined,
  MoonOutlined,
  StopOutlined,
  SunOutlined,
} from "@ant-design/icons";

export const RoutesList = () => {
  const go = useGo();
  const { pathname } = useLocation();
  const { createUrl } = useNavigation();
  const t = useTranslate();
  const [route, setRoute] = useState<IRoute>();
  const updateRoute = (route: IRoute) => {
    setRoute(route);
  };
  const [activeOption, setActiveOption] = useState<boolean>(true);
  const [timeOption, setTimeOption] = useState<string>("AM");
  const [sportsOption, setSportsOption] = useState<boolean>(false);
  const organization = localStorage.getItem("organization");

  const { data: driversData } = useList<IDriver>({
    resource: "drivers",
    pagination: {
      mode: "off",
    },
  });
  const drivers = driversData?.data;

  const { data: vehiclesData } = useList<IVehicle>({
    resource: "vehicles",
    pagination: {
      mode: "off",
    },
  });
  const vehicles = vehiclesData?.data;

  const getDriver = (driverId: string): string | null => {
    const driver = drivers?.find((driver) => driver.id === driverId);
    return driver ? driver.name : null;
  };

  const getVehicle = (vehicleId: number): string | null => {
    const vehicle = vehicles?.find((vehicle) => vehicle.id === vehicleId);
    return vehicle
      ? `${vehicle.vehicle_make} ${vehicle.vehicle_model} ${vehicle.vehicle_year}`
      : null;
  };

  const getStops = (stopList: IStop[]): string[] => {
    return stopList.map((stop) => stop.address);
  };

  const { isLoading, triggerExport } = useExport<IRoute>({
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    mapData: (item) => {
      return {
        id: item.id,
        name: item.name,
        description: item.description,
        start_time: item.start_time,
        duration: item.duration + " minutes",
        distance: item.distance + " miles",
        weekdays: item.weekdays,
        stops: getStops(item.stop_list),
        driver: getDriver(item.driver),
        vehicle: getVehicle(item.vehicle),
        vehicle_type: item.vehicle_type,
      };
    },
  });

  return (
    <List
      breadcrumb={false}
      headerButtons={(props) => [
        <Segmented
          value={timeOption}
          options={[
            {
              label: t("routes.fields.AM"),
              value: "AM",
              icon: <SunOutlined />,
            },
            {
              label: t("routes.fields.PM"),
              value: "PM",
              icon: <MoonOutlined />,
            },
          ]}
          onChange={(value) => setTimeOption(value)}
        />,
        <Checkbox value={sportsOption} onChange={(value) => setSportsOption(value.target.checked)}>Sports</Checkbox>,
        <Segmented
          value={activeOption}
          options={[
            {
              label: t("routes.fields.isActive.true"),
              value: true,
              icon: <CheckCircleOutlined />,
            },
            {
              label: t("routes.fields.isActive.false"),
              value: false,
              icon: <StopOutlined />,
            },
          ]}
          onChange={(value) => setActiveOption(value)}
        />,
        <CreateButton
          {...props.createButtonProps}
          key="create"
          size="large"
          onClick={() => {
            return go({
              to: `${createUrl("routes")}`,
              query: {
                to: pathname,
              },
              options: {
                keepQuery: true,
              },
              type: "replace",
            });
          }}
        >
          {t("routes.actions.add")}
        </CreateButton>,
        <ExportButton
          size="large"
          onClick={triggerExport}
          loading={isLoading}
        />,
      ]}
    >
      <Divider />
      <Row gutter={16} wrap>
        <Col xs={24} md={12} lg={10}>
          <RoutesListCard
            setRouteCard={updateRoute}
            route={route}
            activeOption={activeOption}
            timeOption={timeOption}
            sportsOption={sportsOption}
          />
        </Col>
        <Col
          xs={24}
          md={12}
          lg={14}
          style={{
            height: "650px",
            overflow: "hidden",
          }}
        >
          <RouteStops stops={route?.stop_list ?? []} />
        </Col>
      </Row>
    </List>
  );
};
