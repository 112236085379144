export const calculateTotalDuration = (directions: any): number => {
    if (!directions || !directions.routes || directions.routes.length === 0) {
      return 0;
    }
  
    const route = directions.routes[0];
    let totalDurationInSeconds = 0;
  
    if (route.legs && route.legs.length > 0) {
      route.legs.forEach((leg: { duration: { value: number; }; }) => {
        if (leg.duration && leg.duration.value) {
          totalDurationInSeconds += leg.duration.value;
        }
      });
    }
  
    // Convert total duration to minutes
    const totalDurationInMinutes = Math.floor(totalDurationInSeconds / 60);
  
    return totalDurationInMinutes;
  };
  