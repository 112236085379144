import { Col, Row, Spin } from "antd";
import { StopMap } from "../map";
import { useStopForm } from "./useStopForm";
import { StopFormFields } from "./fields";
import { UseFormProps } from "@refinedev/antd";

type Props = {
  action: UseFormProps["action"];
};

export const StopForm = (props: Props) => {
  const {
    stop,
    handleMapOnDragEnd,
    formProps,
    saveButtonProps,
    handleAddressChange,
    formLoading,
    isFormDisabled,
    setIsFormDisabled,
    latLng,
  } = useStopForm({
    action: props.action,
  });

  return (
    <Spin spinning={formLoading}>
      <Row gutter={16} wrap>
        <Col xs={24} md={12} lg={9}>
          <StopFormFields
            formProps={formProps}
            saveButtonProps={saveButtonProps}
            action={props.action}
            isFormDisabled={isFormDisabled}
            setIsFormDisabled={setIsFormDisabled}
            handleAddressChange={handleAddressChange}
          />
        </Col>
        <Col
          xs={24}
          md={12}
          lg={15}
          style={{
            height: props.action === "create" ? "calc(100vh - 300px)" : "432px",
          }}
        >
          <StopMap
            lat={latLng?.lat}
            lng={latLng?.lng}
            zoom={props.action === "create" ? 4 : 6}
            stop={stop}
            isDisabled={isFormDisabled}
            onDragEnd={handleMapOnDragEnd}
          />
        </Col>
        <Col
          xs={24}
          sm={{
            span: 24,
            offset: 0,
          }}
          lg={{
            span: 15,
            offset: 9,
          }}
        >
          {props.action === "edit" && (
            <div
              style={{
                marginTop: "16px",
              }}
            >
              {/* <StopCourierTable stop={stop} /> */}
            </div>
          )}
        </Col>
      </Row>
    </Spin>
  );
};
