import { useNavigation, useInfiniteList } from "@refinedev/core";
import {
  Typography,
  List as AntdList,
  Divider,
  List,
  Spin,
  Card,
  Flex,
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { IRoute } from "../../../interfaces";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RouteIcon from "@mui/icons-material/Route";
import { NumberField } from "@refinedev/antd";
import { useStyles } from "./styled";

type Props = {
  height?: string;
  routes: IRoute[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isLoading?: boolean;
};

export const RoutesList = ({
  height = "432px",
  routes,
  hasNextPage,
  fetchNextPage,
  isLoading,
}: Props) => {
  const { edit } = useNavigation();
  const { styles } = useStyles();

  return (
    <div
      id="scrollableDiv"
      style={{
        display: "block",
        height: height,
        overflow: "auto",
      }}
    >
      <InfiniteScroll
        dataLength={routes.length}
        next={() => fetchNextPage()}
        hasMore={hasNextPage || false}
        loader={
          <Spin
            spinning
            style={{
              height: "56px",
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          />
        }
        endMessage={<Divider plain>That&apos;s all, nothing more.</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={routes}
          grid={{
            gutter: [16, 16],
            column: 1,
          }}
          renderItem={(item) => {
            return (
              <List.Item style={{ height: "100%" }}>
                <Card
                  hoverable
                  onClick={() => edit("routes", item.id)}
                  className={styles.card}
                  styles={{
                    body: {
                      padding: "16px 16px 0 16px",
                    },
                    cover: {
                      position: "relative",
                    },
                    actions: {
                      marginTop: "auto",
                    },
                  }}
                >
                  <Card.Meta
                    title={
                      <Flex>
                        <Typography.Title
                          level={5}
                          style={{ display: "flex", gap: "5px" }}
                        >
                          {item.vehicle_type == "van" ? (
                            <AirportShuttleIcon sx={{ color: "#1677ff" }} />
                          ) : item.vehicle_type === "bus" ? (
                            <DirectionsBusIcon sx={{ color: "#1677ff" }} />
                          ) : item.vehicle_type === "car" ? (
                            <DirectionsCarIcon sx={{ color: "#1677ff" }} />
                          ) : null}
                          {item.name}
                        </Typography.Title>
                      </Flex>
                    }
                    description={
                      <Flex justify="space-between">
                        <Typography style={{ display: "flex", gap: "5px" }}>
                          <AccessTimeIcon
                            sx={{ color: "#1677ff" }}
                            fontSize="small"
                          />
                          {item?.duration ?
                          <NumberField
                            value={item?.duration || 0}
                            options={{
                              style: "unit",
                              unit: "minute",
                            }}
                          />
                          : "N/A"}
                        </Typography>
                        <Typography style={{ display: "flex", gap: "5px" }}>
                          <RouteIcon sx={{ color: "#1677ff" }} fontSize="small" />
                          <NumberField
                            value={item.distance}
                            options={{
                              style: "unit",
                              unit: "mile",
                            }}
                          />
                        </Typography>
                        {/* <PickUpStudents stops={item.stop_list} />
                        <DropOffStudents stops={item.stop_list} /> */}
                      </Flex>
                    }
                  />
                </Card>
              </List.Item>
            );
          }}
        />
      </InfiniteScroll>
    </div>
  );
};
