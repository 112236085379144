import { useEffect, useRef, useState } from "react";
import { useNavigation, useTranslate } from "@refinedev/core";
import { DeleteButton, ListButton, SaveButton, useForm } from "@refinedev/antd";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  InputRef,
  Row,
  theme,
} from "antd";
import { IVehicle } from "../../interfaces";
import { CardWithContent, FormItemHorizontal } from "../../components";
import {
  EditOutlined,
  LeftOutlined,
  RightCircleOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";

export const VehicleEdit = () => {
  const titleInputRef = useRef<InputRef>(null);

  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const t = useTranslate();
  const { list } = useNavigation();
  const { formProps, queryResult, saveButtonProps } = useForm<IVehicle>();
  const { token } = theme.useToken();

  useEffect(() => {
    if (!isFormDisabled) {
      titleInputRef.current?.focus();
    }
  }, [isFormDisabled]);

  return (
    <>
      <Flex>
        <ListButton icon={<LeftOutlined />}>
          {t("vehicles.vehicles")}
        </ListButton>
      </Flex>
      <Divider />

      <Row gutter={16}>
        <Col>
          <Form {...formProps} layout="horizontal" disabled={isFormDisabled}>
            <Card
              styles={{
                body: {
                  padding: 0,
                },
              }}
            >
              <FormItemHorizontal
                icon={<RightCircleOutlined />}
                label={t("vehicles.fields.vehicle_make")}
                name="vehicle_make"
              >
                <Input />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<RightCircleOutlined />}
                label={t("vehicles.fields.vehicle_model")}
                name="vehicle_model"
              >
                <Input />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<RightCircleOutlined />}
                label={t("vehicles.fields.vehicle_year")}
                name="vehicle_year"
              >
                <Input />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<RightCircleOutlined />}
                label={t("vehicles.fields.color")}
                name="color"
              >
                <Input />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<RightCircleOutlined />}
                label={t("vehicles.fields.capacity")}
                name="passenger_capacity"
              >
                <InputNumber />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<RightCircleOutlined />}
                label={t("vehicles.fields.fuel_economy")}
                name="fuel_economy"
              >
                <InputNumber />
              </FormItemHorizontal>
              <Divider
                style={{
                  margin: "0",
                }}
              />
              <FormItemHorizontal
                icon={<RightCircleOutlined />}
                label={t("vehicles.fields.license")}
                name="required_license"
              >
                <Input />
              </FormItemHorizontal>
            </Card>
            <Flex
              align="center"
              justify="space-between"
              style={{
                padding: "16px 16px 0px 16px",
              }}
            >
              {isFormDisabled ? (
                <>
                  <DeleteButton
                    type="text"
                    onSuccess={() => {
                      list("vehicles");
                    }}
                    style={{
                      marginLeft: "-16px",
                    }}
                  />
                  <Button
                    style={{
                      marginLeft: "auto",
                    }}
                    disabled={false}
                    icon={<EditOutlined />}
                    onClick={() => setIsFormDisabled(false)}
                  >
                    {t("actions.edit")}
                  </Button>
                </>
              ) : (
                <>
                  <Button onClick={() => setIsFormDisabled(true)}>
                    {t("actions.cancel")}
                  </Button>
                  <SaveButton
                    {...saveButtonProps}
                    disabled={isFormDisabled}
                    style={{
                      marginLeft: "auto",
                    }}
                    htmlType="submit"
                    type="primary"
                    icon={null}
                  >
                    Save
                  </SaveButton>
                </>
              )}
            </Flex>
          </Form>
        </Col>
        {/* <Col span={15}>
          <CardWithContent
            bodyStyles={{
              height: "400px",
              overflow: "hidden",
            }}
            icon={
              <ShoppingOutlined
                style={{
                  fontSize: 14,
                  color: token.colorPrimary,
                }}
              />
            }
            title={t("dashboard.recentOrders.title")}
          ></CardWithContent>
        </Col> */}
      </Row>
    </>
  );
};
