import {
  Children,
  cloneElement,
  Dispatch,
  FC,
  isValidElement,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

interface MapProps extends Omit<google.maps.MapOptions, "center"> {
  setMap?: Dispatch<SetStateAction<google.maps.Map | undefined>>;
  center?: google.maps.LatLngLiteral;
  onDragStart?: () => void;
}

interface ChildProps {
  map?: google.maps.Map;
  updateBounds?: (bounds: google.maps.LatLngBounds) => void;
}

const MapComponent: FC<PropsWithChildren<MapProps>> = ({
  children,
  center = { lat: 42.9552965, lng: -85.5780721 }, // Default center coordinates
  zoom = 6, // Default zoom level
  onDragStart,
  mapId,
  setMap: setMapFromProps,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      const mapConstructor = new window.google.maps.Map(ref.current, {
        mapId,
        center,
        zoom,
        ...options,
      });
      setMap(mapConstructor);
      setMapFromProps?.(mapConstructor);
    }
  }, [ref, map, mapId, center, zoom, options, setMapFromProps]);

  useEffect(() => {
    if (map) {
      map.setOptions({ ...options, zoom });
      if (onDragStart) {
        google.maps.event.clearListeners(map, "dragstart");
        map.addListener("dragstart", onDragStart);
      }
    }
  }, [map, options, zoom, onDragStart]);

  const updateBounds = (bounds: google.maps.LatLngBounds) => {
    if (map) {
      map.fitBounds(bounds);
    }
  };

  return (
    <>
      <div ref={ref} style={{ flexGrow: 1, height: "100%" }} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child as React.ReactElement<ChildProps>, { map, updateBounds });
        }
        return null;
      })}
    </>
  );
};

type MapWrapperProps = {
  mapProps?: MapProps;
};

const MapWrapper: FC<PropsWithChildren<MapWrapperProps>> = ({
  children,
  mapProps,
}) => {
  return (
    <Wrapper apiKey={import.meta.env.VITE_APP_MAP_ID}>
      <MapComponent {...mapProps}>{children}</MapComponent>
    </Wrapper>
  );
};

export default MapWrapper;
