import { useTranslate } from "@refinedev/core";
import { ListButton } from "@refinedev/antd";
import { Flex, Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { StopForm } from "../../components";

export const StopsCreate = () => {
  const t = useTranslate();

  return (
    <>
      <Flex>
        <ListButton icon={<LeftOutlined />}>{t("stops.stops")}</ListButton>
      </Flex>
      <Divider />
      <StopForm action="create" />
    </>
  );
};
