import { useLink } from "@refinedev/core";
import { Space, theme, Image } from "antd";
import { IMAGE_PATHS } from "../../constants";
import { Logo } from "./styled";
import { useConfigProvider } from "../../context";

type TitleProps = {
  collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { mode } = useConfigProvider();
  const Link = useLink();

  return (
    <Logo>
      <Link to="/">
        {collapsed ? (
          <Image src={IMAGE_PATHS.logoSmall} width={40} />
        ) : (
          <Space
            style={{
              marginTop: "10px",
            }}
          >
            <Image src={mode === 'light' ? IMAGE_PATHS.logo : IMAGE_PATHS.logoDark} width={150} />
          </Space>
        )}
      </Link>
    </Logo>
  );
};
