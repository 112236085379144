import { Col, Row, Spin } from "antd";
import { useRouteForm } from "./useRouteForm";
import { RouteFormFields } from "./fields";
import { UseFormProps } from "@refinedev/antd";
import { RouteStops } from "../../stop";

type Props = {
  action: UseFormProps["action"];
};

export const RouteForm = (props: Props) => {
  const {
    route,
    formProps,
    saveButtonProps,
    handleChange,
    formLoading,
    isFormDisabled,
    setIsFormDisabled,
  } = useRouteForm({
    action: props.action,
  });

  return (
    <Spin spinning={formLoading}>
      <Row gutter={16} wrap>
        <Col xs={24} md={12} lg={10}>
          <RouteFormFields
            formProps={formProps}
            saveButtonProps={saveButtonProps}
            action={props.action}
            isFormDisabled={isFormDisabled}
            setIsFormDisabled={setIsFormDisabled}
            handleChange={handleChange}
          />
        </Col>
        <Col
          xs={24}
          md={12}
          lg={14}
          style={{
            height: props.action === "create" ? "calc(100vh - 300px)" : "700px",
          }}
        >
          <RouteStops stops={route?.stop_list ?? []} />
        </Col>
      </Row>
    </Spin>
  );
};
