import { Row, Col, theme, Segmented, Spin, Flex, Divider, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import {
  CardWithContent,
  RoutesList,
  MultiRouteMapComponent,
} from "../../components";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  MoonOutlined,
  StopOutlined,
  SunOutlined,
} from "@ant-design/icons";
import { List } from "@refinedev/antd";
import { useInfiniteList } from "@refinedev/core";
import { IRoute } from "../../interfaces";
import { useEffect, useState } from "react";

export const DashboardPage: React.FC = () => {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const organization = localStorage.getItem("organization");
  const [activeOption, setActiveOption] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [timeOption, setTimeOption] = useState<string>("AM");
  const [sportsOption, setSportsOption] = useState<boolean>(false);

  const { data, isLoading, hasNextPage, fetchNextPage } =
    useInfiniteList<IRoute>({
      resource: "routes",
      filters: [
        {
          field: "organization_id",
          operator: "eq",
          value: organization,
        },
        {
          field: "isActive",
          operator: "eq",
          value: activeOption,
        },
        {
          field: "start_time",
          operator: "contains",
          value: timeOption,
        },
        {
          field: "sports",
          operator: "eq",
          value: sportsOption,
        },
      ],
    });

  const routes = data?.pages.flatMap((page) => page.data) || [];

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [data]);

  return (
    <List
      title={t("dashboard.overview.title")}
      breadcrumb={false}
      headerButtons={(props) => [
        <Segmented
          value={timeOption}
          options={[
            {
              label: t("routes.fields.AM"),
              value: "AM",
              icon: <SunOutlined />,
            },
            {
              label: t("routes.fields.PM"),
              value: "PM",
              icon: <MoonOutlined />,
            },
          ]}
          onChange={(value) => setTimeOption(value)}
        />,
        <Checkbox value={sportsOption} onChange={(value) => setSportsOption(value.target.checked)}>Sports</Checkbox>,
        <Segmented
          value={activeOption}
          options={[
            {
              label: t("routes.fields.isActive.true"),
              value: true,
              icon: <CheckCircleOutlined />,
            },
            {
              label: t("routes.fields.isActive.false"),
              value: false,
              icon: <StopOutlined />,
            },
          ]}
          onChange={(value) => setActiveOption(value)}
        />,
      ]}
    >
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xl={9} lg={9} md={24} sm={24} xs={24}>
          <RoutesList
            height={"650px"}
            routes={routes}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isLoading={isLoading}
          />
        </Col>
        <Col xl={15} lg={15} md={24} sm={24} xs={24}>
          <CardWithContent
            bodyStyles={{
              height: "600px",
              overflow: "hidden",
              padding: 0,
            }}
            icon={
              <ClockCircleOutlined
                style={{
                  fontSize: 14,
                  color: token.colorPrimary,
                }}
              />
            }
            title={t("dashboard.deliveryMap.title")}
          >
            {loading ? (
              <Flex justify="center" align="center" style={{ height: "100%" }}>
                <Spin tip="Loading..." size="large" />
              </Flex>
            ) : (
              <MultiRouteMapComponent routes={routes} />
            )}
          </CardWithContent>
        </Col>
      </Row>
    </List>
  );
};
