import { useState } from "react";

import { useGetIdentity, useLogout } from "@refinedev/core";

import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Grid, Popover, Space, Typography } from "antd";

// import { AccountSettings } from "./account-settings";
import { IIdentity, IUser } from "../../interfaces";
import { AccountSettings } from "../account-settings";

const { useBreakpoint } = Grid;

export const CurrentUser: React.FC = () => {
  const [opened, setOpened] = useState(false);
  const { data: user } = useGetIdentity<IIdentity>();
  const { mutate: logout } = useLogout();
  const screens = useBreakpoint();

  const content = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <Typography.Text
        strong
        style={{
          padding: "12px 20px",
        }}
      >
        {user?.name}
      </Typography.Text> */}
      <div
        style={{
          borderTop: "1px solid #d9d9d9",
          padding: "4px",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Button
          style={{ textAlign: "left" }}
          icon={<SettingOutlined />}
          type="text"
          block
          onClick={() => setOpened(true)}
        >
          Account settings
        </Button>
        <Button
          style={{ textAlign: "left" }}
          icon={<LogoutOutlined />}
          type="text"
          danger
          block
          onClick={() => logout()}
        >
          Logout
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Popover
        content={content}
        trigger="click"
        overlayInnerStyle={{ padding: 0 }}
        overlayStyle={{ zIndex: 999 }}
      >
        <Space style={{ cursor: "pointer", marginBottom: 2 }} size={screens.md ? 16 : 8} align="center">
          <Typography.Text>{user?.name}</Typography.Text>
          <Avatar
            icon={<UserOutlined />}
          />
        </Space>
      </Popover>
      {user && (
        <AccountSettings
          opened={opened}
          setOpened={setOpened}
          userId={user.id}
          userName={user.name}
        />
      )}
    </>
  );
};
