import { useTranslate } from "@refinedev/core";
import { ListButton } from "@refinedev/antd";
import { Flex, Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { StopForm } from "../../components";
import _debounce from "lodash/debounce";

export const StopsEdit = () => {
  const t = useTranslate();

  return (
    <>
      <Flex>
        <ListButton icon={<LeftOutlined />}>{t("stops.title")}</ListButton>
      </Flex>
      <Divider />
      <StopForm action="edit" />
    </>
  );
};
