import {
  useTranslate,
  useExport,
  useGo,
  useNavigation,
  useMany,
  useList,
} from "@refinedev/core";
import {
  List,
  useTable,
  ExportButton,
  EditButton,
  DateField,
} from "@refinedev/antd";
import { Table, Typography, theme } from "antd";
import { IDriver, IRoute, ISchool, ITrip } from "../../interfaces";
import { EyeOutlined } from "@ant-design/icons";
import { PaginationTotal } from "../../components";
import { PropsWithChildren } from "react";
import { TripStatus } from "../../components/trips";

export const TripsList = ({ children }: PropsWithChildren) => {
  const t = useTranslate();
  const organization = localStorage.getItem("organization");

  const { tableProps } = useTable<ITrip>({
    resource: "trips",
    filters: {
      initial: [
        {
          field: "organization_id",
          operator: "eq",
          value: organization,
        },
      ],
    },
    pagination: {
      mode: "off",
    },
  });
  const trips = tableProps.dataSource ?? [];
  const routesIds = trips.map((item) => item.route);
  const { data: routesData } = useMany<IRoute>({
    resource: "routes",
    ids: routesIds,
    queryOptions: {
      enabled: routesIds.length > 0,
    },
  });

  const { data } = useList<IRoute>({
    resource: "routes",
    pagination: {
      mode: "off",
    },
  });
  const routes = data?.data;

  const { data: driversData } = useList<IDriver>({
    resource: "drivers",
    pagination: {
      mode: "off",
    },
  });
  const drivers = driversData?.data;

  const getRoute = (routeId: number): string | null => {
    const route = routes?.find((route) => route.id === routeId);
    return route ? route.name : null;
  };

  const getDriver = (driverId: string): string | null => {
    const driver = drivers?.find((driver) => driver.id === driverId);
    return driver ? driver.name : null;
  };

  const { isLoading, triggerExport } = useExport<ITrip>({
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    mapData: (item) => {
      return {
        id: item.id,
        route: getRoute(item.route),
        driver: getDriver(item.driver),
        status: item.status,
        start_time: item.start_time,
        end_time: item.end_time,
      };
    },
  });

  return (
    <List
      breadcrumb={false}
      headerButtons={(props) => (
        <ExportButton
          size="large"
          onClick={triggerExport}
          loading={isLoading}
        />
      )}
    >
      <Table
        {...tableProps}
        rowKey="id"
        scroll={{ x: true }}
        pagination={{
          ...tableProps.pagination,
          showTotal: (total) => (
            <PaginationTotal total={total} entityName="trips" />
          ),
        }}
      >
        <Table.Column<IRoute>
          title={t("trips.fields.route")}
          dataIndex="route"
          key="route"
          render={(value) => {
            const route = routesData?.data.find((route) => route?.id === value);

            return (
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {route?.name || "-"}
              </Typography.Text>
            );
          }}
        />
        <Table.Column<IDriver>
          title={t("trips.fields.driver")}
          dataIndex="driver"
          key="driver"
          render={(value) => {
            const driver = driversData?.data.find((driver) => driver?.id === value);

            return (
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {driver?.name || "-"}
              </Typography.Text>
            );
          }}
        />
        <Table.Column
          dataIndex="status"
          title={t("trips.fields.status.label")}
          sorter
          render={(value) => <TripStatus value={value} />}
        />
        <Table.Column
          dataIndex={"start_time"}
          title={t("trips.fields.start_time")}
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column
          dataIndex={"end_time"}
          title={t("trips.fields.end_time")}
          render={(value) => <DateField value={value} format="LLL" />}
        />
        {/* <Table.Column
          title={t("table.actions")}
          key="actions"
          fixed="right"
          align="center"
          render={(_, record: ISchool) => {
            return (
              <EditButton
                icon={<EyeOutlined />}
                hideText
                recordItemId={record.id}
              />
            );
          }}
        /> */}
      </Table>
      {children}
    </List>
  );
};
